.profile_pic_rectangle {
  box-sizing: border-box;

  position: absolute;
  width: 239px;
  min-height: auto;

  background: #e8f5f5;
  border: 1px solid #1fcac5;
}
.profile_edit_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 6px 6px 4px;
  gap: 4px;

  height: 40px;
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: right;

  color: #5f5f5f;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  background: #ffffff;
  border: 1px solid #5f5f5f;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.pro_img {
  width: 120px;
  height: 120px;

  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.2));
  border-radius: 15px;
}
.pro_input {
  box-sizing: border-box;
  padding: 5px;
  width: 98%;
  height: 50px;
  left: 282px;
  top: 248px;

  background: #e6e1e1;
  border-radius: 4px;
}
.pro_label {
  height: 18px;
  left: 283px;
  margin: 2px;
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  color: #018786;
}
