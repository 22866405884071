.addteam_btn {
  width: 245px;
  height: 60px;
  border: none;
  background: #1fcac5;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #ffff;
}
.team_backbtn {
  width: 90px;
  height: 40px;
  border: none;
  background: #1fcac5;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #ffff;
}
.team_Rectangle {
  box-sizing: border-box;

  width: 50%;
  height: auto;
  text-align: center;
  background: #f3fcfc;
  border: 1px solid #1fcac5;
  border-radius: 2px;
}
.addteam_input {
  width: 70%;
  height: 43px;
  border: none;
  background: #e6f3f3;
  border-radius: 4px;
}
.team_cancel_btn {
  width: 100px;
  height: 40px;

  background: #f8f8f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  text-transform: capitalize;

  color: #000000;
  border: none;
}

.onteam_hover:hover {
  background-color: #f3fcfc;
  /* background-image: url(../../../assets/images/sidebar/threedot.png); */
}
.onteam_hover:hover > p {
  display: block !important;
  cursor: pointer;
}
.right_menus {
  /* position: relative; */
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: right;
  border: #000;
}
.dropdown_menus {
  display: none;
  position: absolute;
  background-color: #ffffff;
  text-align: center;
  width: 10%;
  z-index: 1;

  cursor: pointer;
  float: right;
  margin: 10px;
}
.dropdown_menus p:hover {
  background-color: #eaf8f7;
  color: #000;
}
.right_menus .dropdown_menus {
  display: block;
}
