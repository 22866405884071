.back_img {
  width: 100%;
  border-radius: 0px 560px 560px 0px;
  background: linear-gradient(180.27deg, #1fcac5 0.23%, #2bdb9f 91.46%);
  height: 100vh;
}
.login_input {
  box-sizing: border-box;

  width: 336px;
  height: 50px;

  background: #ffffff;
  border: 0.5px solid #c9c9c9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.userlogin_btn {
  width: 85px;
  height: 40px;

  background: #1fcac5;
  box-shadow: 0px 0px 8px -1px rgba(2, 63, 61, 0.34);
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  border: none;
  color: #ffffff;
}
.sinup_rectangle {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 30%;
  height: 451px;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #8b8b8b;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}
.card_body {
  box-sizing: border-box;

  width: 100%;
  height: auto;

  background: #ffffff;
  border: 0.5px solid #8b8b8b;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}
.signup_input {
  box-sizing: border-box;

  width: 336px;
  height: 40px;

  background: #ffffff;
  border: 0.5px solid #c9c9c9;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
