.chat_sidebar {
  list-style: none;
  width: 200px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1fcac5;
}
.chat_heading {
  width: 100%;
  height: 54px;

  background: #04d9d4;
  font-family: "Roboto";
}

.chat_link {
  align-items: center;
  display: flex;
  color: #000000;
  padding: 13px 4px;
  transition: all 0.5s;
}
.chat_link:hover {
  background: #c6f4f3;
  color: #fff;
  transition: all 0.5s;
}
.chat_link_text {
  color: #1fcac5;
  font-family: "Roboto";
}
.chat_link_text:hover {
  color: #1fcac5;
  font-family: "Roboto";
}
.chat_input {
  box-sizing: border-box;

  width: 100%;
  height: 55px;

  border: 0.5px solid #1fcac5;
  border-radius: 8px;
}
.member_sidebar > ul > li {
  cursor: pointer;
  padding: 5px;
  list-style: none;
  width: 200px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
}
.member_sidebar > ul > li:hover {
  background: #dfecec;
}
.msg_rectangle {
  display: inline-block;
  width: max-content;
  padding: 5px;
  background: #d0fffe;
  border-radius: 4px;
}
.circle {
  height: 50px;
  width: 50px;
  background-color: #018786;
  display: block;
  cursor: pointer;
  border-radius: 100px;
}
