#approach_main {
  padding: 4rem 0;
}
#approach_main .approach_text {
  padding: 0rem 0rem 0rem 14rem;
}
#approach_main .approach_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 76px;
  color: #04b2ae;
}
#approach_main .approach_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  color: #fe800e;
}

#approach_main .approach_image .HeroImg {
  width: 100%;
  max-width: 75%;
}
#approach_main .approach_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  color: #fe800e;
}
#approach_main .approach_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 55px;
  color: #04b2ae;
}

#approach_main .approach_text {
  padding: 0rem;
}
#approach_main .approach_image .HeroImg {
  width: 100%;
  max-width: 100%;
}
#brainstorm_main .brainstorm_text {
  padding: 3rem 1rem;
}

#brainstorm_main {
  background: rgba(180, 15, 15, 0.06);
}

#brainstorm_main .brainstorm_text {
  padding: 0 8rem;
}
#brainstorm_main .brainstorm_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #f48c49;
}

#brainstorm_main .brainstorm_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}

#brainstorm_main .brainstorm_image {
  position: relative;
  left: -15px;
  padding: 0;
  margin: 0;
}
img.Brainstorm {
  width: 100%;
  max-width: 100%;
}
/* Ideation_main */

#Ideation_main {
  padding: 5rem 0;
  background: rgba(68, 180, 15, 0.06);
}
#Ideation_main .Ideation_text {
  padding: 0rem 5rem 0rem 10rem;
}

#Ideation_main .Ideation_textIcon {
  padding: 0rem 0rem 0rem 11rem;
}
#Ideation_main .Ideation_textIcon p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2b2b2b;
}
#Ideation_main .Ideation_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 76px;
  color: #04b2ae;
}
#Ideation_main .Ideation_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Ideation_main .Ideation_image {
  position: relative;
  margin: 0;
  right: -15px;
  padding: 0;
}

#Ideation_main .Ideation_image .Ideation {
  width: 100%;
  max-width: 100%;
}
#Ideation_main .Ideation_textIcon {
  padding: 0rem 0rem 2rem 1rem;
}
#Ideation_main {
  padding: 0;
  background: rgba(68, 180, 15, 0.06);
}
#Ideation_main .Ideation_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 76px;
  color: #04b2ae;
}
#Ideation_main .Ideation_text {
  padding: 0rem 1rem 0rem 1rem;
}
#Ideation_main .Ideation_textIcon {
  padding: 0rem 0rem 0rem 11rem;
}
#Ideation_main .Ideation_textIcon p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2b2b2b;
}
/* ******** development Styling *********** */

#development_main {
  background: rgba(180, 154, 15, 0.08);
}

#development_main .development_text {
  padding: 0 8rem;
}
#development_main .development_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #e1d919;
}

#development_main .development_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2b2b2b;
}

#development_main .development_image {
  position: relative;
  left: -15px;
  padding: 0;
  margin: 0;
}
#development_main .development_text {
  padding: 0 1rem;
}
img.development {
  width: 100%;
  max-width: 100%;
}
/* Feedback_main */

#Feedback_main {
  padding: 5rem 0;
  background: rgba(68, 180, 15, 0.06);
}
#Feedback_main .Feedback_text {
  padding: 0rem 5rem 0rem 10rem;
}

#Feedback_main .Feedback_textIcon {
  padding: 0rem 0rem 0rem 14rem;
}
#Feedback_main .Feedback_textIcon p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Feedback_main .Feedback_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 76px;
  color: #005f98;
}
#Feedback_main .Feedback_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Feedback_main .Feedback_image {
  position: relative;
  margin: 0;
  right: -15px;
  padding: 0;
}

#Feedback_main .Feedback_image .Feedback {
  width: 100%;
  max-width: 100%;
}
#Feedback_main .Ideation_text {
  padding: 3rem 1rem;
}
#Feedback_main .Feedback_text {
  padding: 0rem 1rem 0rem 1rem;
}
/* ********** customization *************** */

#customization_main {
  text-align: center;
  position: relative;
  padding: 4rem 0;
  background: rgba(255, 226, 183, 0.12);
}
#customization_main .customization-text {
}
#customization_main .customization-text h3 {
  padding: 1rem 0 2rem 0;
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #f48c49;
}
#customization_main .customization-text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  color: #2b2b2b;
  padding: 2rem 0 0 0;
}

#customization_main .customization-text .Customize {
  width: 48%;
  max-width: 50%;
}
#PeopleSitting_main {
  text-align: center;
  background-image: url("../../assets/images/our process/Yellow-back.png");
  width: 100%;
  padding: 4rem 0;
}
.PeopleSitting {
  width: 64%;
}
#footer_main {
  padding: 5rem 0;
}
#footer_main .footer-content {
}
#footer_main .footer-content p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  text-align: center;
  color: #2b2b2b;
}
/* =========mobile screen  */
.mobile_rectangle1 {
  width: 100%;
  height: 254px;

  background: rgba(255, 157, 0, 0.15);
}
.mobile_rectangle2 {
  width: 100%;
  height: 254px;

  background: rgba(255, 157, 0, 0.3);
}
