.team_heading {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  text-align: center;

  color: #05b0ac;
}

.team_card1 {
  width: 100%;
  background: rgba(255, 122, 1, 0.07);
  padding-bottom: 38px;
}
.ceo_card {
  background: linear-gradient(180deg, #04b2ae 0%, #029591 100%);
  border-bottom: 5px dashed #f48c49;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.team_name {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height */

  color: #ffffff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.team_card2 {
  width: 100%;
  padding-bottom: 40px;
  background: rgba(28, 189, 174, 0.07);
}
.team_membar_card {
  /* padding: 35px 40px; */
  gap: 0px;

  /* background: #ffffff; */
  /* border-bottom: 10px solid #ffc329; */
  /* box-shadow: -6px 13px 19px rgba(0, 0, 0, 0.3); */
  /* border-radius: 16px; */
  /* margin-bottom: 50px; */
}
.team_member_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: 0.2px;

  /* second-text-color */

  color: #737373;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.team_mem_name {
  display: flex;
  justify-content: center;

  font-family: "Dosis";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.1px;

  /* text-color */

  color: #252b42;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}
.team_membar_card:hover {
  /* background-color: #fafafa; */
  /* box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%); */
  /* cursor: pointer; */
}
@media only screen and (max-width: 768px) {
  .ceo_card {
    text-align: center;
  }
  .team_membar_card {
    margin-top: 50px;
    /* margin-bottom: 50px; */
  }
  .ceo_card {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.team-ali {
  gap:10px
}


.team-employee-images{
  max-width: 64%;
}