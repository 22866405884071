.task_card_body {
  background: #e7f3f3;
  min-height: 100vh;
  padding: 5px;
  border-radius: 4px;
  width: 95%;
  min-height: 550px;
  overflow-x: hidden;
}
.card-header {
  width: 90%;
  height: 40px;
  border-radius: 4px;

  color: #ffff;
  background: #20cac7;
}
.add_task_btn {
  width: 256px;
  height: 35px;
  border: none;
  background: #e6eaea;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */

  text-transform: capitalize;

  color: #9aa4a7;
}
.subTask_body {
  width: 100%;
  padding-bottom: 10px;

  background: #ffffff;
  border-radius: 8px;
}
.card_text_input {
  box-sizing: border-box;
  color: #000;
  width: 90%;
  height: 45px;
  outline: none;
  border: 0.5px solid #c0bebe;
  border-radius: 4px;
}
.card_date {
  width: 50%;
  height: 38px;

  background: #fbf8f8;
  border-radius: 4px;
}
.task_done_btn {
  width: 40%;
  height: 45px;
  color: white;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background: #1fcac5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.taskbody_scroll {
  max-height: 550px;
  overflow-y: auto;
}
.active_Tab {
  /* width: 43px;
  height: 5px;

  background: #b3b3b3;
  border-radius: 2.5px; */
}
.task_show_tab {
  box-sizing: border-box;

  width: 100%;

  background: #ffffff;
  border: 0.5px solid rgba(64, 61, 61, 0.5);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.task_comp_tab {
  box-sizing: border-box;

  width: 100%;

  background: #9ccc65;
  border: 0.5px solid rgba(64, 61, 61, 0.5);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.right-menu {
  /* position: relative; */
  margin-left: -19px;
  margin-top: -12px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #ffffff;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  float: left;
}
.dropdown-menu p:hover {
  background-color: rgb(156, 152, 152);
  color: #000;
}
.right-menu:hover .dropdown-menu {
  display: block;
}

.secondary-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400px;
  color: #7d7d7d;
}

.taskInformation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  min-height: 106px;
  border-radius: 5px;
  max-width: 311px;
  /* background: ${({ isDragging }) =>
    isDragging ? 'rgba(255, 59, 59, 0.15)' : 'white'}; */
  background: white;
  margin-top: 15px;
}
.taskList {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  min-width: 341px;
  border-radius: 5px;
  padding: 15px 15px;
  margin-right: 45px;
}

.taskColumnStyles {
  margin: 8px;
  display: flex;
  width: 100%;
  min-height: 80vh;
}

title {
  color: #10957d;
  background: rgba(16, 149, 125, 0.15);
  padding: 2px 10px;
  border-radius: 5px;
  align-self: flex-start;
}
.edit_task_input {
  box-sizing: border-box;

  width: 100%;
  height: 40px;

  border: 0.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
.edit_des_input {
  box-sizing: border-box;

  width: 100%;
  height: 80px;
  background: #f4fbfb;
  border: 0.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 4px;
}
.edit_date_input {
  width: 120px;
  height: 25px;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 12px;
}
.edit_remove_btn {
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  border: none;
  width: 22%;
  height: 40px;

  background: #ff725e;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.edit_save_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  border: none;

  text-transform: capitalize;

  color: #ffffff;
  align-items: center;
  padding: 14px 28px;
  gap: 10px;

  width: 22%;
  height: 40px;

  background: #20cac7;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.task_backbtn {
  width: 90px;
  height: 40px;
  border: none;
  background: #1fcac5;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  color: #ffff;
}
.task_asign_name {
  font-size: 13px;
  font-weight: 500;
}
.task_asigned_name {
  font-size: 13px;
  font-weight: 600;
}
.autocomplete-wrapper .dropdown {
  width: 100%;
  padding: 0;
  text-align: left;
  max-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #0f67ff;
  border-top: none;
  z-index: 500;
}
.viewMember_btn {
  padding: 8px 12px 12px 12px;
  gap: 4px;
  height: 40px;
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #5f5f5f;
  flex: none;
  flex-grow: 0;
  background: #ffffff;
  border: 1px solid #5f5f5f;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
@import url("https://fonts.googleapis.com/css?family=Arima+Madurai:300");
*,
*::before,
*::after {
  box-sizing: border-box;
}
h1 {
  font-family: "Arima Madurai", cursive;
  color: black;
  font-size: 4rem;
  letter-spacing: -3px;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.6);
  position: relative;
  z-index: 3;
}

.bird {
  background-image: url("../../assets/images/rocket.svg");
  background-size: auto 100%;
  width: 140px;
  height: 125px;
  will-change: background-position;
  /* animation-name: fly-cycle; */
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
}
.bird--one {
  animation-duration: 1s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 60%;
  left: -10%;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  animation-name: fly-right-one;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.bird-container--one {
  animation-duration: 5s;
  animation-delay: 0;
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}
@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(6vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(0vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(8vh) translateX(70vw) scale(0.4);
  }
  60% {
    transform: translateY(0vh) translateX(70vw) scale(0.3);
  }
  100% {
    transform: translateY(10vh) translateX(70vw) scale(0);
  }
}
@keyframes fly-right-two {
  0% {
    /* transform: translateY(-2vh) translateX(-10vw) scale(0.5); */
  }
  10% {
    /* transform: translateY(0vh) translateX(10vw) scale(0.4); */
  }
  20% {
    /* transform: translateY(-4vh) translateX(30vw) scale(0.6); */
  }
  30% {
    /* transform: translateY(1vh) translateX(50vw) scale(0.45); */
  }
  40% {
    /* transform: translateY(-2.5vh) translateX(70vw) scale(0.5); */
  }
  50% {
    /* transform: translateY(0vh) translateX(70vw) scale(0.45); */
  }
  51% {
    /* transform: translateY(0vh) translateX(70vw) scale(0.45); */
  }
  100% {
    transform: translateY(0vh) translateX(70vw) scale(0.45);
  }
}
