.header_rectangle {
  height: 74px;

  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.body_rectangle {
  box-sizing: border-box;

  height: 78px;

  background: #f8f8f8;
  border-radius: 4px;
}
#att_date_input {
  padding: 6px 8px;

  height: 32px;

  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}
.present_check {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #392;
  appearance: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  background-color: #392;
}

.absent_check {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #a40000;
  appearance: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  background-color: #a40000;
}

.leave_check {
  border: 2px solid white;
  box-shadow: 0 0 0 1px #a0a400;
  appearance: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  background-color: #a0a400;
}
.half_leave_check {
  border: 2px solid white;
  box-shadow: 0 0 0 1px orange;
  appearance: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  background-color: orange;
}
.half_leave_check:checked {
  background-color: orange;
}
.wfh_check {
  border: 2px solid white;
  box-shadow: 0 0 0 1px rgb(68, 68, 150);
  appearance: none;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  background-color: rgb(68, 68, 150);
}
.wfh_check:checked {
  background-color: rgb(68, 68, 150);
}
.chek_sumart_btn:hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 11px 3px 3px;

  height: 34px;

  background: #ffffff;
  border: 1px solid #6e6b7b;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
