.tabs-border {
  border-bottom: 1px solid #c4c4c4;
}
.tab-button-filled {
  background-color: #04b2ae;
  border: none;
  padding: 10px 10px;
}
.tab-button-unfilled {
  background-color: transparent;
  border: none;
  padding: 10px 10px;
}
