@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

/* .bg-Case-4 {
  background-image: url("../../../assets/images/OptimusCaseStudy/optimusHero.png");
  padding: 0 0 6rem 0;
  width: 100%;
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.hero-images {
  list-style: none;
}
.hero-images:nth-child(n + 1) {
  text-align: end;
}
.bgCase5Div .hero-images:nth-child(n + 1) {
  text-align: center;
}
#bg-case-1 .StockChainHeader .hero-images .StockLogo1 {
  position: relative !important;
  left: 1rem !important;
  top: 6px !important;
}
img.rounded.optimusLogo.float-left {
  position: relative;
  padding: 0;
  top: 0px;
  width: 100%;
  left: -22px;
  max-width: 11rem;
}
.bgCase5Div .hero-Content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  line-height: 79px;
  font-family: "Dosis", sans-serif;
}
.hero-Content {
  list-style: none;
}
.hero-Content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 79px;
  font-family: "Dosis", sans-serif;
}
.bgCase5Div .hero-Content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  line-height: 79px;
  font-family: "Dosis", sans-serif;
}
img.rounded.illustrationHealth {
  position: relative;
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 11rem;
  left: 3rem;

  background-size: cover;
}
img.rounded.illustrationHealth {
  position: relative !important;
  top: -13rem !important;
}
#bg-case-1 .StockChainHeader img.rounded.illustrationHealth {
  position: relative !important;
  top: -8.5rem !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: 7rem !important;
  left: 0rem !important;
  background-position: 100% center !important;
  background-size: cover !important;
}
img.rounded.illustrationHealth {
  position: relative;
  top: -12.5rem;
  padding: 0;
  width: 100%;
  max-width: 11rem;
  left: 0rem;
  background-position: 100% center;
  background-size: cover;
}
.optimusHeroContent #deserveBestYou p {
  font-family: "Bradley Hand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: start;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.optimusHeroContent #deserveBestYou h3 {
  font-family: "Monoton", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 26.6574px;
  line-height: 41px;
  letter-spacing: 0.16em;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 5.33147px 53.3147px rgba(255, 255, 255, 0.5);
}
.optimus_heroContentText h3 {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  font-size: 1.855rem;
  line-height: 40px;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.5);
}
.optimus_heroContentText p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: justify;
  justify-content: center;
  line-height: 24px;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#personalisedCard .card {
  background: linear-gradient(180deg, #2c2c2c 0%, rgba(44, 44, 44, 0) 70.83%);
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.8));
  border-radius: 6px;
  border: 0;
  padding: 0rem 0 3rem 0;
}
#personalisedCard .cardbody {
  text-align: center;
}
#personalisedCard .cardbody h5 {
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Bitter", serif;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
img.SleepVector {
  padding: 6% 0 0 0;
}
img.HealthVector {
  margin: -14px 0rem;
}

img.rounded.renderMedical {
  position: relative;
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 35.125;
  mix-blend-mode: luminosity;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.3));
}
.bg-Case-4_backImg1 {
  background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png");
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  background-position: right;
}
.problemStatementMain {
  text-align: center !important;
}

img.problemStatementIcon {
  width: 100%;
  max-width: 426px;
}
img.problemStatementImg {
  width: 100% !important;
  max-width: 100% !important;
}
img.problemStatementImg {
  width: 100%;
  max-width: 515px;
}
.bg-Case-4_Hero .covidFearMain {
  margin: 4rem 0;
}
.bg-Case-4_Hero .covidFear {
  background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle12.png");
  width: 100%;
  padding: 5rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
}
.bg-Case-4_Hero .covidFear p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 7rem;
}
.bg-Case-4_Hero .covidFear .Rectangle13 {
  width: 100%;
  background-color: #fff;
  height: 16.625rem;
}

.bg-Case-4_Hero .covidFear .Rectangle12 {
  width: 100%;
  background: #fff;
}
.bg-Case-4_Hero .covidFear {
  padding: 1rem 0 !important;
  background-repeat: no-repeat !important;
}
.bg-Case-4_Hero .covidFear p {
  padding: 0 1rem !important;
}
.bg-Case-4_Hero .covidFear {
  padding: 1rem 0 !important;
  background-repeat: no-repeat !important;
  background-image: none !important;
  background-color: #e7e7e7 !important;
}
.bg-Case-4_backImg2 {
  background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png");
  background-repeat: no-repeat;
  position: relative;
  background-size: auto;
  background-position: left;
}
.bg-Case-4_backImg1,
.bg-Case-4_backImg2,
.bg-Case-4_backImg3 {
  background-image: none !important;
}
.problemStatementMain {
  text-align: end;
}
img.SolutionImg {
  width: 30rem;
}
img.SolutionImg {
  width: 100% !important;
  max-width: 100% !important;
}
.bg-Case-4_Hero .covidFearMain2 {
  margin: 4rem 0;
}
.bg-Case-4_Hero .covidFear2 {
  background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle13.png");
  width: 100%;
  padding: 5rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
}
.bg-Case-4_Hero .covidFear2 p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 7rem;
}
.bg-Case-4_backImg3 {
  background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png");
  background-repeat: no-repeat;
  position: relative;
  background-size: auto;
  background-position: top right;
}
.problemStatementMains {
  text-align: center !important;
}
.problemStatementMains {
  text-align: end;
}
img.businessOpportunityIcon {
  width: 100% !important;
  max-width: 100% !important;
}
img.businessOpportunityIcon {
  width: 472px;
}
img.businessOpportunityImg {
  width: 332px;
}

.bg-Case-4_Hero .covidFearMain3 {
  margin: 5rem 0 0 0;
}
.bg-Case-4_Hero .covidFearMain3 {
  margin: 2rem 0 0 0;
}
.bg-Case-4_Hero .covidFear3 {
  background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle14.png");
  width: 100%;
  padding: 3rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
}
.bg-Case-4_Hero .covidFear3 p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 8rem;
}
.bg-Case-4_Hero .covidFear3 p {
  padding: 0 3rem !important;
}

.bg-Case-4_Hero .covidFear3 {
  padding: 1rem 0 !important;
  background-image: none;
  background-color: #e7e7e7 !important;
}

#OptimusSurveyAnalysis {
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
  background-size: 100% 100%;
}

#OptimusSurveyAnalysis .OptimusSurveyTitle img {
  margin-bottom: -5rem;
  width: 100%;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #ffffff;
}
#OptimusSurveyAnalysis .analysisCountingMain {
  padding: 5rem 0 0 0;
}
#OptimusSurveyAnalysis .analysisCountingMain .analysisCountingImg {
  text-align: center;
}
#OptimusSurveyAnalysis .analysisCountingMain .analysisCountingImg img {
  width: 100%;
  max-width: 25rem;
}
.analysisCountingImg {
  text-align: center !important;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle img {
  margin-bottom: -5rem;
  width: 100%;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #ffffff;
} */

#link {
  text-decoration: none;
}

.nav-links {
  font-family: "Dosis", sans-serif;
  font-size: 16px;
}
.bg-menu {
  /* background-image: url("../../../assets/images/menu.png"); */
  background-repeat: no-repeat;
  background-position: center;
}

.bg-menu-2 {
  /* background-image: url("../../../assets/images/members.png"); */
  background-repeat: no-repeat;
  background-position: center;
}

.bg-menu-3 {
  /* background-image: url("../../../assets/images/project.svg"); */
  background-repeat: no-repeat;
  background-position: center;
}
.bg-add {
  /* background-image: url("../../../assets/images/add.svg"); */
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 20px;
}
.bg-hero {
  /* background-image: url("../../../assets/images/Frame.png"); */
  background-repeat: no-repeat;
  background-position: bottom right;
}

#HeroSectionImg {
  background-image: linear-gradient(
    111deg,
    rgb(140 198 63 / 44%) 0%,
    rgb(29 149 217 / 62%) 100%
  );
  padding: 10rem 0;
  background-position: center;
  background-size: cover;
  opacity: 2;
}
#HeroSectionImg .HeroSectionContent {
  padding: 4rem 0;
}
#bg-case-1 {
  /* background: #950014; */
  background-image: url("../../assets/images/stock/Group4836.png");

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#bg-case-1 .StockChainHeader {
}
#bg-case-1 .StockChainHeader .hero-images {
}
#bg-case-1 .StockChainHeader .hero-Content {
}
#bg-case-1 .StockChainHeader .hero-images .StockLogo1 {
}
#bg-case-1 .StockChainHeader .hero-images .StockLogo2 {
}
#bg-case-1 .StockChainHero .StockChainHeroText1 {
  padding: 5rem 0;
}

#bg-case-1 .StockChainHero .StockChainHeroText1 h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  padding: 0 0 0 1.5rem;
  line-height: 75px;
  text-align: start;
  color: #e8e8e8;
}
#bg-case-1 .StockChainHero .StockChainHeroText1 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 45px;
  padding: 0 0 0 1.5rem;
  color: #e8e8e8;
}
#bg-case-1 .StockChainHero .StockChainHeroText2 {
  padding: 10rem 0 2.8rem 0;
}
#bg-case-1 .StockChainHero .StockChainHeroText2 p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  padding: 0 0 0 1.5rem;
  color: #141414;
}
#StockProblemStatement {
  padding: 4rem 0;
}
#StockProblemStatement .ProblemStatementCard {
  padding: 3rem;
}
#StockProblemStatement .ProblemStatementCard .card {
  border: none;
}
#StockProblemStatement .ProblemStatementCard .card .card-title {
  background: linear-gradient(90deg, #005bff 0%, #00dbff 100%);
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #25274d;
  padding: 1rem;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
  text-align: center;
}
#StockProblemStatement .ProblemStatementCard .card .card-body {
  background: linear-gradient(135deg, #e8e8e8 0%, #ffffff 100%);
  box-shadow: -21px 21px 42px rgba(229, 229, 229, 0.2),
    21px -21px 42px rgba(229, 229, 229, 0.2),
    -21px -21px 42px rgba(255, 255, 255, 0.9),
    21px 21px 53px rgba(229, 229, 229, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(229, 229, 229, 0.5);
}

#StockProblemStatement .ProblemStatementCard .card .card-body .card-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #141414;
}
#StockProblemStatement .ProblemStatementImg {
  text-align: center;
}
#StockProblemStatement .ProblemStatementImg img.Problemillustration {
  width: 100%;
  max-width: 28.047rem;
  background-position: center;
  background-size: cover;
}
#StockProblemStatement .ProblemStatementImg img.StockChain_Illustration1 {
  width: 100%;
  max-width: 31.063rem;
  background-position: center;
  background-size: cover;
}
#StockProblemStatement .ProblemStatementImg img.stockMarket1 {
  width: 100%;
  max-width: 20.398rem;
  background-position: center;
  background-size: cover;
}
#StockSurveyAnalysis {
  padding: 5rem 0;
  padding-bottom: 4rem;
  background: #25274d;
}
li.margin-right-20.nav-item :hover {
  border-bottom: 2px solid #0eb5b1;
}
#StockSurveyAnalysis .StockAnalysisContentMain {
  background: linear-gradient(90deg, #005bff 0%, #00dbff 100%);
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #1d1f3c;
  text-align: center;
}
#StockSurveyAnalysis .StockAnalysisContentMain p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding: 0.5rem 0;
  margin: 0;
  line-height: 45px;
  color: #ffffff;
}
#StockSurveyAnalysis .StockAnalysisContent {
  padding: 7rem 0 0 0;
}
#StockSurveyAnalysis .StockAnalysisContent p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 45px;
  color: #ffffff;
}
#StockSurveyAnalysis .StockAnalysisContent p span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 45px;
  color: #ffffff;
}
#StockSurveyAnalysis .StockAnalysisContentCardImg {
}
#StockSurveyAnalysis .StockAnalysisContentCardImg img {
  width: 100%;
}

#StockInterviewQuestions {
  padding: 5rem 0;
  padding-bottom: 3rem;
  background-color: #c4d6e7;
  
}
#StockInterviewQuestions .StockInterviewImage {
}
#StockInterviewQuestions .StockInterviewImage1 img {
  width: 100%;
  max-width: 36.355rem;
}
#StockInterviewQuestions .StockInterviewImage2 img {
  width: 100%;
  max-width: 65rem;
}

#StockMainLearning {
  padding: 5rem 0;
  padding-bottom: 3rem;
  background-color: #c4edeb;
}
#StockMainLearning .StockLearningContentMain {
  background: linear-gradient(90deg, #005bff 0%, #00dbff 100%);
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #1d1f3c;
  text-align: center;
}
#StockMainLearning .StockLearningContentMain p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding: 0.5rem 0;
  margin: 0;
  line-height: 45px;
  color: #ffffff;
}

#StockMainLearning .StockAnalysisContentCardImg {
  text-align: center;
}
#StockMainLearning .StockAnalysisContentCardImg img {
  width: 100%;
  max-width: 31rem;
}

#StockAbouts {
  background-position: center;
  background-size: cover;
  padding: 6rem 0;
  /* padding-bottom: 3rem; */
  background-color: #c4d6ed;
}
#StockAbouts .StockLearningContentMain {
  background: linear-gradient(90deg, #005bff 0%, #00dbff 100%);
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #1d1f3c;
  text-align: center;
}
#StockAbouts .StockLearningContentMain p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding: 0.5rem 0;
  margin: 0;
  line-height: 45px;
  color: #ffffff;
}
#StockAbouts .firstAboutContent {
  padding: 6rem 0 0 0;
}

#StockAbouts .firstAboutContent .aboutText {
  padding: 1.5rem 2rem;
}
#StockAbouts .firstAboutContent .aboutText p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: #141414;
}
#StockAbouts .firstAboutContent .aboutText span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#StockAbouts .firstAboutContent .aboutgroupImg {
  text-align: center;
}
#StockAbouts img.hassanMir {
  width: 100%;
  max-width: 40.813rem;
}
#StockAbouts img.muneebNawaz {
  width: 100%;
  max-width: 37.625rem;
}

#StockAbouts .firstAboutContent #questionAnswering ul {
  align-items: baseline;
  padding: 0 0 0 1.5rem;
}
#interviewQSection #generallyAbout .generallyAboutContent {
  padding: 0 0 0 1rem;
}
.generallyAboutContent {
  padding: 0 0 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#StockAbouts .firstAboutContent #questionAnswering p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #141414;
}
/* *****************StockEmpathyMap********************* */

#StockEmpathyMap {
  background: #005bff;
  padding: 4rem 0;
}

#StockEmpathyMap .StockEmpathyImg {
  text-align: center;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg346 {
  width: 100%;
  max-width: 28.375rem;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg347 {
  width: 100%;
  max-width: 28.313rem;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg348 {
  width: 100%;
  max-width: 28.75rem;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg344 {
  width: 100%;
  max-width: 32.875rem;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg349 {
  width: 100%;
  max-width: 28.313rem;
}
#StockEmpathyMap .StockEmpathyImg img.GroupImg350 {
  width: 100%;
  max-width: 28.75rem;
}
#StockEmpathyMap .StockEmpathyImg img.iconInfinite {
  width: 100%;
  max-width: 26.625rem;
}

/* StockMobileAppDesign */

#StockMobileAppDesign {
  background-color: #f5f5f5;
  padding: 5rem 0;
}
img.illustrationLogistics {
  width:80%;
}

#StockMobileAppDesign .StockLearningContentMain {
  background: linear-gradient(90deg, #005bff 0%, #00dbff 100%);
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #1d1f3c;
  text-align: center;
}
#StockMobileAppDesign .StockLearningContentMain p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  padding: 0.5rem 0;
  margin: 0;
  line-height: 45px;
  color: #ffffff;
}

#FooterStockLogo {
  padding: 3rem 0;
  background: #c6f7ff;
}
#FooterStockLogo .FooterStockImg {
  text-align: center;
}
#FooterStockLogo .FooterStockImg img {
  width: 100%;
  max-width: 35rem;
}

/* ****************************************** Stock Chain **************************** */

#TascsurveyAnalysis .surveyContent {
  padding: 10rem 0rem 10rem 5rem;
  position: relative;
}

#TascsurveyAnalysis .surveyContent .mainsurveyImg {
  text-align: center;
  padding: 40rem 0 0 0;
}

#TascsurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}

#TascsurveyAnalysis .surveyContent .subTitle {
  padding: 1rem 0 0 0;
  font-size: 35px;
  color: #1d1d1d;
}
#TascsurveyAnalysis .surveyImg {
  padding: 25rem 0 0 0;
}
#TascsurveyAnalysis .surveyImg {
}
#TascsurveyAnalysis .surveyCircle {
}
#TascsurveyAnalysis .surveyCircle img {
}
#surveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #fff;
}

#TascsurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}
.bg-case-bottom {
}
.bg-case-2 {
  background-image: url("../../assets//images/purplebacknaseem.png");
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
  padding: 0rem 0 16rem 0;
  justify-content: center;
}
.hero-images {
  list-style: none;
}
/* .UiCaseStudy{
    padding: 4rem 0 15rem 0;
  } */
.UiCaseStudy {
  padding: 4rem 0 6rem 0;
}
.hero-images:nth-child(n + 1) {
  text-align: center;
}
img.rounded.Group3 {
  position: relative;
  top: 1rem;
  width: 100%;
  max-width: 20%;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.kids19201.float-left {
  max-width: 344px;
  position: relative;
  right: 5rem;
  width: 100%;
}

.hero-Content {
  list-style: none;
}
.hero-Content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 79px;
  font-family: "Dosis", sans-serif;
}
.naseemEducation {
  padding: 0rem 0 3rem 0;
}
.naseemEducation h3 {
  font-size: 60px;
  line-height: 76px;
  font-weight: 800;
  font-style: normal;
  color: #ffffff;
  font-family: "Dosis", sans-serif;
}
.naseemEducation p {
  color: #ffffff;
  font-family: "Dosis", sans-serif;
  font-size: 30px;
  font-style: normal;
  line-height: 38px;
  font-weight: 500;
}
.bg-case-3 {
  /* background-image: url("../../../assets/images/bg-case-3.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
/* #surveyAnalysis {
    background-image: url("../../../assets/images/storeBg.png");
    width: 100%;
    max-width: 100%;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
  } */
#NaseemsurveyAnalysis {
  /* background-image: url("../../../assets/images/Path95.png"); */
  width: 100%;
  max-width: 100%;
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
}
#qurbanisurveyAnalysis {
  /* background-image: url("../../../assets/images/path15.png"); */
  width: 100%;
  max-width: 100%;
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
}
#TascsurveyAnalysis {
  /* background-image: url("../../../assets/images/Path01.png"); */
  width: 100%;
  max-width: 100%;
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
}
.mainsurveyImg {
  text-align: center;
  padding: 15rem 0 0 0;
}
#surveyAnalysis .surveyContent {
  padding: 10rem 0rem 0rem 5rem;
  position: relative;
}
img.rounded.img-fluid.Group4836 {
  left: 10rem;
  position: relative;
  top: -2.3rem;
}
#AnalysisCardTop {
  position: relative;
  top: -2.2rem;
}
img.rounded.img-fluid.Group4837 {
  float: left;
  position: relative;
  left: 7.3rem;
}

img.rounded.img-fluid.GroupSecond4837 {
  float: right;
  position: relative;
  right: 5rem;
}
#NaseemsurveyAnalysis .surveyContent {
  padding: 10rem 0rem 10rem 5rem;
  position: relative;
}

#NaseemsurveyAnalysis .surveyContent .mainsurveyImg {
  text-align: center;
  padding: 40rem 0 0 0;
}

#surveyAnalysis .surveyContent .title {
  font-weight: 600;
  opacity: 1;
  font-size: 45px;
  line-height: 60px;
  width: fit-content;
  color: #fff;
  padding: 0.5rem 1rem;
}
#NaseemsurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}
#surveyAnalysis .surveyContent .subTitle {
  padding: 1rem 0 0 0;
  font-size: 35px;
  color: #fff;
}
#NaseemsurveyAnalysis .surveyContent .subTitle {
  padding: 1rem 0 0 0;
  font-size: 35px;
  color: #1d1d1d;
}
#NaseemsurveyAnalysis .surveyImg {
  padding: 25rem 0 0 0;
}
#surveyAnalysis .surveyImg,
#NaseemsurveyAnalysis .surveyImg {
}
#surveyAnalysis .surveyCircle,
#NaseemsurveyAnalysis .surveyCircle {
}
#surveyAnalysis .surveyCircle img,
#NaseemsurveyAnalysis .surveyCircle img {
}
#surveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #fff;
}

#NaseemsurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}

/* &&&&&&&&&&&&&&&& */

#qurbanisurveyAnalysis .surveyContent {
  padding: 10rem 0rem 10rem 5rem;
  position: relative;
}

#qurbanisurveyAnalysis .surveyContent .mainsurveyImg {
  text-align: center;
  padding: 40rem 0 0 0;
}

#surveyAnalysis .surveyContent .title {
  font-weight: 600;
  opacity: 1;
  font-size: 45px;
  line-height: 60px;
  width: fit-content;
  color: #fff;
  padding: 0.5rem 1rem;
}
#qurbanisurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}
#surveyAnalysis .surveyContent .subTitle {
  padding: 1rem 0 0 0;
  font-size: 35px;
  color: #fff;
}
#qurbanisurveyAnalysis .surveyContent .subTitle {
  padding: 1rem 0 0 0;
  font-size: 35px;
  color: #1d1d1d;
}
#qurbanisurveyAnalysis .surveyImg {
  padding: 25rem 0 0 0;
}
#surveyAnalysis .surveyImg,
#qurbanisurveyAnalysis .surveyImg {
}
#surveyAnalysis .surveyCircle,
#qurbanisurveyAnalysis .surveyCircle {
}
#surveyAnalysis .surveyCircle img,
#qurbanisurveyAnalysis .surveyCircle img {
}
#surveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #fff;
}

#qurbanisurveyAnalysis .surveyCircle .circleTitle {
  padding: 1rem 0 0 0;
  font-size: 25px;
  color: #1d1d1d;
}

#surveyAnalysis .mainsurveyImg .surveyImgl {
}

#surveyAnalysis .mainsurveyImg .surveyImg img,
#surveyAnalysis .mainsurveyImg .surveyImg img {
  width: 100%;
  max-width: 50%;
}
/* ******************* .bg-case-4 **************** */
.bg-Case-4 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/optimusbackground.svg"); */
  /* padding: 22px 100px 0rem 153px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10px;
background-color: #1A1A1A;
  /* background-position: 100% 100%;
  background-repeat: no-repeat; */
}
.bg-case-img {
}
img.rounded.optimusLogo.float-left {
  position: relative;
  padding: 0;
  top: 0px;
  width: 100%;
  left: -22px;
  max-width: 11rem;
}
img.rounded.illustrationHealth {
  position: relative;
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 11rem;
  left: 3rem;
  /* background-position: 100% center; */
  background-size: cover;
  mix-blend-mode: hard-light;
}
.optimus_heroContentText {
}
.optimus_heroContentText h3 {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  font-size: 1.855rem;
  line-height: 40px;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  text-shadow: 0px 0px 40px rgba(255, 255, 255, 0.5);
}
.optimus_heroContentText p {
  width: 100%;
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;

  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}

.optimusHeroContent #deserveBestYou p {
  font-family: "Bradley Hand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: start;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.optimusHeroContent #deserveBestYou h3 {
  font-family: "Monoton", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 26.6574px;
  line-height: 41px;
  letter-spacing: 0.16em;
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  text-shadow: 0px 5.33147px 53.3147px rgba(255, 255, 255, 0.5);
}

img.rounded.renderMedical {
  position: relative;
  top: 0;
  padding: 0;
  width: 100%;
  max-width: 35.125;
  mix-blend-mode: luminosity;
  filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.3));
}
.moblie_rendermedical {
  /* background: url(3d-render-medical-background-with-vitruvian-style-male-figure.png); */
  mix-blend-mode: luminosity;
  /* filter: drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.3)); */
}
#personalisedCard .card {
  background: linear-gradient(180deg, #2c2c2c 0%, rgba(44, 44, 44, 0) 70.83%);
  filter: drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.8));
  border-radius: 6px;
  border: 0;
  padding: 0rem 0 3rem 0;
}
#personalisedCard .cardbody {
  text-align: center;
}
.problemStatementMain {
  /* text-align: end; */
}
/* no-gutters */
#personalisedCard .cardbody h5 {
  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Bitter", serif;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
img.MotivationVector {
  padding: 30px 2rem;
}
img.HealthVector {
  margin: -14px 0rem;
}
img.SleepVector {
  padding: 6% 0 0 0;
}

img.problemStatementIcon {
  width: 100%;
  max-width: 426px;
}
img.problemStatementImg {
  width: 100%;
  max-width: 100%;
}
img.Group27014 {
  width: 100%;
  max-width: 405.2px;
}
.bg-Case-4_Hero {
  background-color: #f2f2f2;
}
.bg-Case-4_Hero .covidFearMain {
  margin: 4rem 0;
  /* transform: rotate(5deg); */
  transform: skew(-10deg);
  background-color: #E7E7E7;


}

@media (max-width: 768px) {
  .bg-Case-4_Hero .covidFearMain {
    margin: 4rem 0;
    transform: skew(0deg);
    background-color: #F2F2F2;
  }
}

.bg-Case-4_Hero .covidFear {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle12.png"); */
  width: 100%;
  padding: 5rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;

}
.bg-Case-4_Hero .covidFear p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 7rem;
  transform: skew(10deg);

}
.bg-Case-4_Hero .covidFear .Rectangle13 {
  width: 100%;
  background-color: #fff;
  height: 16.625rem;
}

.bg-Case-4_Hero .covidFear .Rectangle12 {
  width: 100%;
  background: #fff;
}
img.SolutionImg {
  width: 30rem;
}
img.Group27014part2 {
  width: 21.875rem;
}

.bg-Case-4_Hero .covidFearMain2 {
  margin: 4rem 0;
  background-color: #E7E7E7;
  transform: skewX(20deg);
  max-width: 100%;

}
@media (max-width: 768px) {
  .bg-Case-4_Hero .covidFearMain2 {
    margin: 4rem 0;
    background-color: #f2f2f2;
    transform: skew(0deg);
  
  }
}
.bg-Case-4_Hero .covidFear2 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle13.png"); */
  width: 100%;
  padding: 5rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;

}
.bg-Case-4_Hero .covidFear2 p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 7rem;
  transform: skewX(-20deg);

}

@media (max-width: 768px) {
.bg-Case-4_Hero .covidFear2 p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 7rem;
  transform: skew(0deg);

}
}
.bg-Case-4_Hero .covidFearMain3 {
  margin: 5rem 0 0 0;
  background-color: #E7E7E7;
  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);


}
@media(max-width:768px) {
.bg-Case-4_Hero .covidFearMain3 {
  margin: 5rem 0 0 0;
  background-color: #F2F2F2;
  clip-path: none;

}
}
.bg-Case-4_Hero .covidFear3 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle14.png"); */
  width: 100%;
  padding: 3rem 0;
  background-position: 100% 100%;
  background-size: cover;
  position: relative;
}
.bg-Case-4_Hero .covidFear3 p {
  font-family: "Bitter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
  padding: 0rem 8rem;
}

img.businessOpportunityIcon {
  width: 472px;
}
img.businessOpportunityImg {
  width: 332px;
}

#bg-Case-4_Container-3 {
  padding: 0 0 0 7.5rem;
}

.bg-Case-4_backImg1 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png"); */
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  background-position: right;
}
.bg-Case-4_backImg2 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png"); */
  background-repeat: no-repeat;
  position: relative;
  background-size: auto;
  background-position: left;
}
.bg-Case-4_backImg3 {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Group270 (14).png"); */
  background-repeat: no-repeat;
  position: relative;
  background-size: auto;
  background-position: top right;
}
.problemStatementMains {
  text-align: end;
}
/* ************************************************************* */

/* ******************* Optimus Survey Analysis Start *******************/

#OptimusSurveyAnalysis {
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5rem 0;
  background-size: 100% 100%;
}

#OptimusSurveyAnalysis .OptimusSurveyTitle {
}
#OptimusSurveyAnalysis .OptimusSurveyTitle img {
  margin-bottom: -5rem;
  width: 100%;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}
#OptimusSurveyAnalysis .OptimusSurveyTitle p span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #ffffff;
}
#OptimusSurveyAnalysis .analysisCountingMain {
  padding: 5rem 0 0 0;
}
#OptimusSurveyAnalysis .analysisCountingMain .analysisCountingImg {
  text-align: center;
}
#OptimusSurveyAnalysis .analysisCountingMain .analysisCountingImg img {
  width: 100%;
  max-width: 25rem;
}
/* ******************* Optimus Survey Analysis End *******************/

/* ******************* Optimus Interview Question Section start *******************/
#interviewQSection {
}
#interviewQSection .interviewQSectionHead {
  /* background-image: url("../../../assets/images/OptimusCaseStudy/Rectangle16.svg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  padding: 9rem 0;
  text-align: center;
}
#interviewQSection .interviewQSectionHead h5 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #1a1a1a;
  text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
}
#interviewQSection .NHS_Doctors {
  padding: 7rem 0;
  background-color: white;
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent {
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent h5 {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 2rem 0;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent ul {
  padding: 1rem 0;
}

#interviewQSection .NHS_Doctors .NHS_DoctorsContent ul li {
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent ul li span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* padding: 2rem 0; */
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#interviewQSection .NHS_Doctors .NHS_DoctorsContent ul li img {
}

#interviewQSection .NHS_Doctors .NHS_DoctorsImg {
}
#interviewQSection .NHS_Doctors .NHS_DoctorsImg img {
  width: 100%;
  max-width: 90%;
  float: left;
}

#interviewQSection #questioned {
  padding: 3rem 0;
}
#interviewQSection #questioned p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#interviewQSection #questionedMain {
  background-color: #f2f2f2;
}
#interviewQSection #questionedMain #questionedImg {
  text-align: center;
}
#interviewQSection #questionedMain #questionedImg img {
  margin-top: -5rem;
}

#interviewQSection #generallyAbout {
  padding: 5rem 0;
  background-color: white;
}
#interviewQSection #generallyAbout .generallyAboutContent {
}
#interviewQSection #generallyAbout .generallyAboutContent ul {
  padding: 1.2rem 0;
}
#interviewQSection #generallyAbout .generallyAboutContent ul li p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}

#interviewQSection #mainLearning {
  padding: 2rem 0;
  background-color: white;
}
#interviewQSection #mainLearning .mainLearningsContent img {
  width: 100%;
  max-width: 20rem;
}

#OptimusUserPersonas {
  background-color: #191919;
  background-repeat: no-repeat;
  padding: 3rem 0;
  padding-top: 8rem;
  background-size: 100% 100%;
}
#OptimusUserPersonas .UserPersonasMain {
  margin: -6rem 0 0 0;
}

#OptimusUserPersonas .OptimusPersonasTitle .GroupImg315 {
  width: 100%;
}

#OptimusAbouts .firstAboutContent {
  padding: 6rem 0 0 0;
}

#OptimusAbouts .firstAboutContent .aboutText {
  display: flex;
  flex-direction: column;
  gap:4rem;
  padding: 4rem 0;
}
#OptimusAbouts .firstAboutContent .aboutText p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#OptimusAbouts .firstAboutContent .aboutText span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#OptimusAbouts .firstAboutContent .aboutgroupImg {
  text-align: end;
}
img.GroupImg326 {
  width: 100%;
  max-width: 320px;
}
#OptimusAbouts .firstAboutContent #questionAnswering ul {
  /* align-items: baseline; */
  padding: 1rem 0 1rem 1.5rem;
}
#interviewQSection #generallyAbout .generallyAboutContent {
  padding: 0 0 0 1rem;
}
.generallyAboutContent {
  padding: 0 0 0 2rem;
}
#OptimusAbouts .firstAboutContent #questionAnswering p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
}
/* *************** */

#OptimusAbouts .secondAboutContent {
  padding: 0 0 6rem 0;
}

#OptimusAbouts .secondAboutContent .aboutText {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 4rem 0;
}
#OptimusAbouts .secondAboutContent .aboutText p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#OptimusAbouts .secondAboutContent .aboutText span {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
#OptimusAbouts .secondAboutContent .aboutgroupImg {
  text-align: end;
}
img.GroupImg326 {
  width: 100%;
  max-width: 320px;
}
#OptimusAbouts .secondAboutContent #questionAnswering ul {
  align-items: baseline;
  padding: 1rem 0 1rem 1.5rem;
}
#interviewQSection #generallyAbout .generallyAboutContent {
  padding: 0 0 0 1rem;
}
.generallyAboutContent {
  padding: 0 0 0 2rem;
}
#OptimusAbouts .secondAboutContent #questionAnswering p {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  color: #1a1a1a;
  text-shadow: 0px 4px 40px rgb(255 255 255 / 50%);
}

img.GroupImg322,
img.GroupImg323,
img.GroupImg324,
img.GroupImg325 {
  width: 100%;
}
img.GroupImg338,
img.GroupImg339,
img.GroupImg340,
img.GroupImg341,
img.GroupImg342,
img.GroupImg343,
img.GroupImg344 {
  width: 100%;
  max-width: 25rem;
}
/* ******************* Optimus Interview Question Section End *******************/
/* ******************* Optimus mobile Sketches  Section start *******************/
#mobileSketches {
  padding: 7rem 0;
  text-align: center;
}
#mobileSketches .mobileSketchesHead {
  text-align: center;
}
#mobileSketches .mobileSketchesHead img {
  width: 100%;
}
#mobileSketches .mobileSketchesImg {
  text-align: center;
  padding: 9rem 0 2rem 0;
}
#mobileSketches .mobileSketchesImg img {
  width: 100%;
  max-width: 90%;
  margin: auto;
}
/* ****************************** */

#mobileWireframe {
  padding: 2rem 0;
  text-align: center;
}
#mobileWireframe .mobileWireframeHead {
  text-align: center;
}
#mobileWireframe .mobileWireframeHead img {
  width: 100%;
}
#mobileWireframe .mobileWireframeImg {
  text-align: center;
  padding: 3rem 0 0 0;
}
#mobileWireframe .mobileWireframeImg img {
  width: 100%;
  max-width: 100%;
  margin: auto;
}
#OptimusMobileAppDesign {
  background-color: #191919;
  background-repeat: no-repeat;
  padding: 5rem 0;
  background-size: 100% 100%;
  text-align: center;
}
#OptimusMobileAppDesign .OptimusMobileAppDesignTitle {
  width: 100%;
  text-align: center;
}
#OptimusMobileAppDesign .OptimusMobileAppDesignTitle img {
  width: 100%;
  max-width: 100%;
}
#OptimusMobileAppDesign .OptimusMobileAppDesignContent {
  text-align: center;
}
#OptimusMobileAppDesign .OptimusMobileAppDesignContent img {
  width: 100%;
  max-width: 100%;
}
#OptimusMobileAppDesign .AppDesignMain {
  margin: -9.3rem -6rem 0rem -6rem;
}

#OptimusContact {
  padding: 2rem 0;
}
#OptimusContact .OptimusContactImg {
  text-align: center;
}
#OptimusContact .OptimusContactImg img {
  width: 100%;
  max-width: 100%;
}
/* ******************* Optimus mobile Sketches Section End *******************/

/* ******************* Qurbani mobile Sketches Section start *******************/

img.rounded.GoatGroup1.float-left {
  width: 100%;
  max-width: 14rem;
}
img.rounded.Group5030.float-left {
  position: relative;
  width: 100%;
  max-width: 100%;
  background-position: 100% center;
  background-size: cover;
}
.bgCase5Div {
  background-image: url("../../assets/images/Group5032.png");
  background-position: 100% center;
  background-size: cover;
  position: relative;
}
#QurbaniProblemStatement {
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/Frame2.svg"); */
  padding: 7rem 0;
  background-position: 100% 100%;
  text-align: center;
  background-size: cover;
  position: relative;
}
#QurbaniSurveyAnalysis {
  background-image: url("../../assets/images/qurbani/blackback.png");
  padding: 10rem 0 0 0;
  background-position: inherit;
  background-size: cover;
  position: relative;
}
.QurbaniSurveyAnalysisMain {
  background-image: linear-gradient(#fff 50%, #474343 50%);
}
.QurbaniInterviewsMain {
  background-image: linear-gradient(#474343 50%, #5f5e5e 50%);
  box-shadow: none;
  border: none;
}
#QurbaniSurveyAnalysis .QurbaniSurveyImg {
  padding: 7rem 0 0 0;
  /* text-align: center; */
}
img.Frame1 {
  width: 100%;
  max-width: 100%;
}
#QurbaniSurveyAnalysis .QurbaniSurveyTitles {
  padding: 7rem 0 0 0;
}
#QurbaniSurveyAnalysis .QurbaniSurveyTitles p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 43px;
  color: #ffffff;
}
#QurbaniSurveyAnalysis .QurbaniSurveyTitles span {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #ffffff;
}
#QurbaniSurveyAnalysis .QurbaniSurveyCardImg {
}
#QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5029 {
  margin: -1.2rem 0 0 0;
  width: 100%;
  max-width: 100%;
}
#QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5028,
#QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5027,
#QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5026 {
  margin: -4.1rem 0 0 0;
  width: 100%;
  max-width: 100%;
}
.QurbaniSurveyCardImgMain {
  padding: 0 0 0 1.5rem;
  /* background-image: url("../../assets/images/qurbani/blackback.png"); */

}

/* ** Interviews ** */

#QurbaniInterviews {
  background-image: url("../../assets/images/qurbani/lightblackishback.png");
  padding: 24rem 0;
  background-position: inherit;
  /* background-size: cover; */
  position: relative;
}
#QurbaniInterviews .QurbaniSurveyTitles p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 43px;
  color: #ffffff;
}
#QurbaniInterviews .QurbaniSurveyTitles h5 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
}
#QurbaniInterviews .QurbaniSurveyTitles span {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #ffffff;
}
#QurbaniInterviews .QurbaniInterviewsImg {
  padding: 10rem 0 0 3.7rem;
}
#QurbaniInterviews .QurbaniInterviewCardImgMain {
  padding: 11rem 0 0 0;
}
#QurbaniInterviews img.Group4866 {
  width: 100%;
  max-width: 100%;
  margin: auto;
}
#QurbaniInterviews .QurbaniInterviewsContent {
}

#QurbaniInterviews .QurbaniInterviewsContent p {
  padding: 0 0 0 1.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
}
#QurbaniInterviews img.Group5033 {
  width: 100%;
  max-width: 378.22px;
}

/* *****************Qurbani main Learnings ********************* */

#QurbanimainLearnings {
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/yellowback.png"); */
  background-image: url("../../assets/images/qurbani/yellowwhite.png");
  z-index: 999;
  opacity: 99%;
  padding: 0rem 0 24rem 0;
  background-position: 100% bottom;
  background-size: 100% 100%;
}
#QurbanimainLearnings .QurbaniLearningsTop {
  position: relative;
  margin: -18rem 0 5rem 0;
}
#QurbanimainLearnings .QurbaniLearningscontentCard .QurbanistarCard {
}
#QurbanimainLearnings .QurbaniLearningscontentCard .QurbaniStarCard img {
  width: 100%;
  max-width: 735.91px;
}
#QurbanimainLearnings .QurbaniLearningscontentCard .QurbaniExecutivesCard {
}
#QurbanimainLearnings .QurbaniLearningscontentCard .QurbaniExecutivesCard img {
  width: 100%;
  max-width: 300px;
}

#QurbaniUserPersonas {
  background-image: url("../../assets/images/qurbani/Group5044.png");
  padding: 0;
  background-position: 100% bottom;
  background-size: 100% 100%;
  height: 1246.99px;
  margin-top: -16.3rem;
  background-color: #7DF598;
}
#QurbaniUserPersonas .QurbaniUserPersonasContent1 {
  padding-top: 15rem;
  text-align: center;
}
#QurbaniUserPersonas .QurbaniUserPersonasContent2 {
  padding-top: 20rem;
  text-align: center;
}
/* ************)*) Qurbani Clients Section (*(***************** */

#Qurbanibg-case-about1 {
  background-position: 100% 100% 100%;
  background-size: cover;
  padding: 5rem 0;
  /* background-color: #a6f8b9; */
  background-image: url("../../assets/images/qurbani/ijazbackgroundgreen.png");

}
#Qurbanibg-case-about1 .QurbaniAbout {
}
#Qurbanibg-case-about1 .QurbaniAbout .QurbaniAboutContent {
  display: flex;
  flex-direction: column;
  gap:15px
}

#Qurbanibg-case-about1 .QurbaniAbout .QurbaniAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #3fd361;
}
#Qurbanibg-case-about1 .QurbaniAbout .QurbaniAboutContent p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #000000;
}
img.Group5016 {
  width: 100%;
  max-width: 100%;
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVector {
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVector img {
  width: 100%;
  height: 450px;
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVectorText {
     display: flex;
  flex-direction: column;
  gap:15px
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVectorText .card {
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVectorText .card .card-body {
  background: #b2f9c2;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #02c12e;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #3fd361;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about1
  .QurbaniAbout
  .AboutVectorText
  .card
  .card-body
  .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #59636d;
}
#Qurbanibg-case-about1 .QurbaniAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #59636d;
}

#about2linearBg {
  background-image: linear-gradient(#fff4f4 50%, #fffbf4 50%);
}
#about4linearBg {
  background-image: linear-gradient(#fffbf4 50%, #12bbf0 50%);
}
#empathyMaplinearBg {
  background-image: linear-gradient(#f4fffc 50%, #12bbf0 50%);
}
/* ***************************************************************************************************************************** */
/* ***************** About2 Sections ********************* */

#Qurbanibg-case-about2 {
  background-position: 100% bottom;
  background-size: 100% 100%;
  padding: 5rem 0;
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/BabyBlueBack.png"); */
}
#Qurbanibgabout2linearBg {
  /* background-image: linear-gradient(#a6f8b9 50%, #e7fdec 50%); */
  /* background-image: url("../../assets/images/qurbani/kamranraufback.png"); */
 background-color: #CAFBD5;
}
/* #painPoints {
  padding: 0 0 20rem 0;
} */
#Qurbanibg-case-about2 .QurbaniAbout {
}
#Qurbanibg-case-about2 .QurbaniAbout .QurbaniAboutContent {
    display: flex;
  flex-direction: column;
  gap:15px
}

#Qurbanibg-case-about2 .QurbaniAbout .QurbaniAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #39433e;
}
#Qurbanibg-case-about2 .QurbaniAbout .QurbaniAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #39433e;
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVector {
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVectorText {
      display: flex;
  flex-direction: column;
  gap:15px
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVectorText .card {
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #3fd361;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #3fd361;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about2
  .QurbaniAbout
  .AboutVectorText
  .card
  .card-body
  .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #39433e;
}
#Qurbanibg-case-about2 .QurbaniAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #39433e;
}

/* ***************** About3 Sections ********************* */
#Qurbanibg-case-about3-top {
  /* width: 100%;   
  height: 200px; 
  background-image: url("../../assets/images/qurbani/ibtisamtopqurbani.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  
}
#Qurbanibg-case-about3 {
  background-position: 100% bottom;
  background-size: 100% 100%;
  padding: 5rem 0;
  background-color: #E7FDEC;
}
#Qurbanibg-case-about3 .QurbaniAbout {
}
#Qurbanibg-case-about3 .QurbaniAbout .QurbaniAboutContent {
  display: flex;
  flex-direction: column;
  gap:15px
}

#Qurbanibg-case-about3 .QurbaniAbout .QurbaniAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #39433e;
}
#Qurbanibg-case-about3 .QurbaniAbout .QurbaniAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #39433e;
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVector {
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVectorText {
       display: flex;
  flex-direction: column;
  gap:15px
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVectorText .card {
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #3fd361;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #3fd361;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about3
  .QurbaniAbout
  .AboutVectorText
  .card
  .card-body
  .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #39433e;
}
#Qurbanibg-case-about3 .QurbaniAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #39433e;
}

/* ********************#Sketches ********************* */

#QurbaniSketchesTop {
  /* background-image: url("../../assets/images/qurbani/Rectangle263.png"); */
   background-color: #66D362;
}

#QurbaniSketches {
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/Rectangle263.png"); */
  padding: 5rem 0;
}

#QurbaniWireframes {
  background-color: #CDCDCD;
  padding: 5rem 0;
}

#QurbaniMobileAppDesign {
  background-color: #a4f8b7;
  padding: 10rem 0 0 0;
}
#QurbaniDesktopDesign {
  background-color: #a4f8b7;
  padding: 10rem 0;
}

/* ***************** About4 Sections ********************* */
#Qurbanibgabout4linearBg {
  /* background-image: linear-gradient(#e7fdec 50%, #f4fffc 50%); */
  /* background-image: url("../../assets/images/qurbani/Group1494.png"); */
  background-color: #F4FFFC;

}
#QurbanibgempathyMaplinearBg {
  background-image: linear-gradient(#f4fffc 50%, #3fd361 50%);
}
#Qurbanibg-case-about4 {
  background-position: inherit;
  background-size: cover;
  padding:  5rem 0;
  background-repeat: no-repeat;
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/Group1494.svg"); */
}
#Qurbanibg-case-about4 .naseemAbout {
}
#Qurbanibg-case-about4 .naseemAbout .naseemAboutContent {
  display: flex;
  flex-direction: column;
  gap:15px
}

#Qurbanibg-case-about4 .naseemAbout .naseemAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #000000;
}
#Qurbanibg-case-about4 .naseemAbout .naseemAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
}
#Qurbanibg-case-about4 .naseemAbout .AboutVector {
}
#Qurbanibg-case-about4 .naseemAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#Qurbanibg-case-about4 .naseemAbout .AboutVectorText {
  display: flex;
  flex-direction: column;
  gap:15px

}
#Qurbanibg-case-about4 .naseemAbout .AboutVectorText .card {
}
#Qurbanibg-case-about4 .naseemAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #2ead4b;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about4 .naseemAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #3fd361;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
  background: #3fd361;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#Qurbanibg-case-about4
  .naseemAbout
  .AboutVectorText
  .card
  .card-body
  .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #000000;
}
#Qurbanibg-case-about4 .naseemAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #000000;
}

/* ******************* Qurbani mobile Sketches Section End *******************/

#problemStatement h3 {
  font-size: 36px;
  font-family: "Dosis", sans-serif;
}
#problemStatement p {
  font-size: 26px;
  font-family: "Dosis", sans-serif;
  line-height: 50px;
}
.text-grey {
  color: #6f7070;
}

.bg-case-5 {
  /* background: #309f5d; */
  /* background-image: url("../../../assets/images/QurbaniCaseStudy/Group5032.svg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 1000px;
}
.bg-caseacc-3 {
  /* background-image: url("../../../assets/images/bg-hardware.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-caseacc-4 {
  /* background-image: url("../../../assets/images/caseacc-4.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.dot {
  background-color: black;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  flex: none;
  margin-right: 10px;
}

.bg-shape {
  /* background-image: url("../../../assets/images/shape-b.png"); */
  background-repeat: no-repeat;
  background-position: bottom right;
  /* margin: 8rem 0; */
}

.bg-shape img.img-responsive.quickLaunch {
  width: 100%;
  max-width: 100%;
}

.bg-team {
  /* background-image: url("../../../assets/images/bg-logo.png"); */
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bg-shape-sm {
  /* background-image: url("../../../assets/images/sm-shape.png"); */
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-shape-sm-2 {
  /* background-image: url("../../../assets/images/shape-sm.png"); */
  background-repeat: no-repeat;
  background-size: contain;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-50-n {
  top: -50px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.hero-heading {
  font-size: 40px;
  font-family: "Dosis", sans-serif;
  color: #fff;
  font-weight: bold;
}
.hero-text {
  font-size: 30px;
  font-family: "Dosis", sans-serif;
  color: #fff;
  font-weight: 400;
}

.base-text {
  font-size: 18px;
  font-family: "Dosis", sans-serif;
}

.features-base-text {
  font-size: 25px;
  font-family: "Dosis", sans-serif;
}

.section-headings {
  font-size: 2rem;
  font-family: "Dosis", sans-serif;
}

.section-headings-features-sm {
  font-size: 40px;
  font-family: "Dosis", sans-serif;
}

.section-headings-sm {
  font-size: 25px;
  font-family: "Dosis", sans-serif;
}

.btn-text {
  font-size: 16px;
  font-family: "Dosis", sans-serif;
}

.text-black {
  color: black !important;
}
.text-green {
  color: #81c784 !important;
}
.text-white {
  color: white !important;
}
.text-grey {
  color: #707070 !important;
}

.text-red {
  color: #f1071f !important;
}
.base-color {
  color: #04b2ae !important;
}

.caps {
  text-transform: uppercase;
}

.font {
  font-family: "Dosis", sans-serif;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #04b2ae !important;
  opacity: 1; /* Firefox */
}

.login-text {
  font-size: 1.5rem !important;
  font-family: "Dosis", sans-serif;
}

.bold {
  font-weight: bold !important;
}

.weight-400 {
  font-weight: 400 !important;
}

.regular {
  font-weight: normal !important;
  line-height: 1.3;
}

.text-mega {
  font-size: 60px;
  line-height: 1.3;
}

.text-center {
  text-align: center;
}
/* Typography */

/* Navbar */

.bg-white {
  background-color: white !important;
}
.bg-blue {
  background-color: #04b2ae !important;
}

.bg-pink {
  background-color: #f25c96 !important;
}

.bg-case-grey {
  background-color: #951a1a !important;
}
.bg-case-dgrey {
  background-color: #757475 !important;
}
.bg-case-purple {
  background-color: #53337e !important;
}
.bg-case-purple2 {
  background-color: #f7f7f7 !important;
}
.bg-case-maroon {
  background-color: #b0b0b0 !important;
}
.bg-case-green {
  background-color: #4daf51 !important;
}
.bg-case-black {
  background-color: #000000 !important;
}
.bg-case-blackq {
  background-color: #8d6e63d3 !important;
}

.nav-logo {
  width: 50%;
}

.border-btn {
  border: 1px solid #0eb5b1 !important;
  background-color: transparent !important;
  color: #0eb5b1 !important;
}

.border-btn:hover {
  border: 1px solid #0eb5b1 !important;
  background-color: #0eb5b1 !important;
  color: #fff !important;
}
.margin-right-20 {
  margin-right: 20px;
}

.grey-box-shadow {
  box-shadow: 0px 3px 6px #00000029;
}

.align-items-center {
  align-items: center;
}

.no-border {
  border: none !important;
}

/* Navbar */

/* borders */
.border-blue {
  border: 1px solid #0eb5b1 !important;
}
/* borders */

/* height */

.h-80 {
  height: 80% !important;
}
.h-90 {
  height: 90% !important;
}

/* width */

.w-100 {
  width: 100%;
}
.w-80 {
  width: 80%;
}
.w-40 {
  width: 40%;
}
/* width */

/* paddings */
.padding-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.padding-220 {
  padding-top: 220px !important;
  padding-bottom: 220px !important;
}

.padding-300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.padding-300-240 {
  padding-top: 300px !important;
  padding-bottom: 240px !important;
}

.padding-btm-140 {
  padding-bottom: 140px !important;
}

.padding-50 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.padding-sides-100 {
  padding: 0 100px;
}

.padding-sides-20 {
  padding: 0 20px;
}

.padding-sides-50 {
  padding: 0 50px;
}

.padding-btn {
  padding: 5px 25px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-200 {
  padding-left: 200px !important;
}

.padding-top-10 {
  padding-top: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .padding-sides-100 {
    padding: 0 20px;
  }
  .padding-70-sm {
    padding: 70px 0px !important;
  }
}

@media only screen and (min-width: 1380px) and (max-width: 2400px) {
  .img-fluid-responsive {
    max-width: 100% !important;
    height: auto !important;
  }
  .features-div {
    font-family: "Dosis", sans-serif !important;
    color: white !important;
    font-weight: 300 !important;
    padding-top: 24rem !important;
  }
}
img.rounded.border1 {
  position: relative;
  width: 100%;
  background-position: 100% center;
  background-size: cover;
  bottom: 9rem;
  left: 32px;
  max-width: 94.5%;
  margin: auto;
}
img.rounded.border2 {
  position: relative;
  width: 100%;
  background-position: 100% center;
  background-size: cover;
  bottom: 9rem;
  left: 32px;
  max-width: 94.5%;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  img.rounded.img-fluid.Group4836 {
    left: 6rem !important;
    position: relative !important;
    top: -2.3rem !important;
  }
  #surveyAnalysis .surveyContent {
    padding: 11rem 0rem 0rem 0rem !important;
    position: relative !important;
  }
  img.rounded.img-fluid.float-end.surveyillustration {
    width: 100% !important;
    max-width: 44% !important;
    position: relative !important;
    top: 5.3rem !important;
    z-index: 2 !important;
    left: 7rem !important;
  }
}
@media only screen and (max-width: 992px) {
  /* New Style */
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.kidseight {
    width: 100% !important;
    height: 100% !important;
    max-width: 40% !important;
  }
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.rightVector {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
    right: 2.4rem !important;
    display: none !important;
  }
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.leftVector {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
    right: 2.4rem !important;
    display: none !important;
  }
  #surveyAnalysis .surveyContent {
    padding: 11rem 0rem 10rem 0rem;
    position: relative;
  }
  img.rounded.img-fluid.float-end.surveyillustration {
    width: 100% !important;
    max-width: 44% !important;
    position: relative !important;
    top: 7rem !important;
    z-index: 2 !important;
    left: 4rem !important;
  }
  #surveyAnalysis #surveyContent {
    padding: 6rem 4rem 0rem 4rem !important;
  }
  img.rounded.border1 {
    position: relative;
    width: 100%;
    background-position: 100% center;
    background-size: cover;
    bottom: 9rem;
    left: 32px;
    max-width: 91%;
    margin: auto;
  }
  img.rounded.border2 {
    position: relative;
    width: 100%;
    background-position: 100% center;
    background-size: cover;
    bottom: 9rem;
    left: 32px;
    max-width: 91.5%;
    margin: auto;
  }

  /* old Style */
  .margin-btm-200 {
    margin-bottom: 300px !important;
  }
  .w-sm {
    width: 100%;
  }
  .text-sm-center {
    text-align: center;
  }
  .sm-flex-center {
    display: flex;
    justify-content: center;
  }

  .robo-sm {
    max-width: 50% !important;
  }
  .top-20-n {
    top: -20px;
  }
  #Interviews .surveyContent {
    width: 100% !important  ;
    max-width: 85% !important;
    margin: 0 !important;
  }
  img.rounded.img-fluid.float-end.Group1469 {
    width: 100% !important;
    max-width: 44% !important;
    position: relative !important;
    bottom: 4.5rem !important;
    z-index: 2 !important;
    left: 0 !important;
  }
  #Interviews #surveyContent {
    padding: 10rem 0rem 0rem 2rem !important;
  }
  #userPersonas .userPersonasFeatures .ExecutivesCard1 {
    position: relative !important;
    bottom: 10.7rem !important;
  }
}
#questionsAsked {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #5a3408;
  padding-top: 2rem;
}
img.rounded.img-fluid.float-end.Group1469 {
  width: 100%;
  max-width: 44%;
  position: relative;
  bottom: 7.5rem;
  z-index: 2;
  left: 1rem;
}
img.rounded.solution1 {
  position: relative;
  top: 1rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.handshakes {
  position: relative;
  top: -1rem;
  width: 100%;
  max-width: 84px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.brainImg {
  position: relative;
  top: 10rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.artificialIntelligence2 {
  position: relative;
  top: 0;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.light {
  position: relative;
  top: 12rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.emojionekeycap2 {
  position: relative;
  top: 1rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.emojionekeycap3 {
  position: relative;
  top: 1rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.GraphImg {
  position: relative;
  top: 7rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}
img.rounded.emojionekeycap1 {
  position: relative;
  top: 1rem;
  width: 100%;
  max-width: 64px;
  background-position: 100% center;
  background-size: cover;
}

img.rounded.img-fluid.float-end.surveyillustration {
  width: 100%;
  max-width: 44%;
  position: relative;
  top: 3.1rem;
  z-index: 2;
  left: 7rem;
}
#QurbaniUserPersonas .QurbaniUserPersonasContent2 img.Group5034 {
  width: 100%;
  max-width: 100%;
}
#QurbaniUserPersonas .QurbaniUserPersonasContent1 img.Group4868 {
  width: 100%;
  max-width: 35%;
  position: relative;
  bottom: 2.3rem;
}
@media only screen and (max-width: 768px) {
  #interviewQSection #questioned {
    padding: 3rem 0 !important;
    text-align: center !important;
  }
  #interviewQSection #questionedMain #questionedImg {
    text-align: center !important;
    padding: 3rem 0 0 0 !important;
  }
  .bg-Case-4_Hero .covidFear3 p {
    padding: 0 3rem !important;
  }
  .bg-Case-4_Hero .covidFearMain3 {
    margin: 2rem 0 0 0;
  }
  .bg-Case-4_Hero .covidFear3 {
    padding: 1rem 0 !important;
    background-image: none;
    background-color: #e7e7e7 !important;
  }
  .bg-Case-4_Hero .covidFear {
    padding: 1rem 0 !important;
    background-repeat: no-repeat !important;
  }
  .bg-Case-4_Hero .covidFear2 {
    padding: 1rem 0 !important;
    background-repeat: no-repeat !important;
  }
  .bg-Case-4_backImg1,
  .bg-Case-4_backImg2,
  .bg-Case-4_backImg3 {
    background-image: none !important;
  }
  .problemStatementMain {
    text-align: center !important;
  }
  .problemStatementMains {
    text-align: center !important;
  }
  img.rounded.illustrationHealth {
    position: relative !important;
    top: -13rem !important;
  }
  #userPersonas .userPersonasFeatures .ExecutivesCard1 {
    position: relative !important;
    bottom: 9.1rem !important;
  }
  #Interviews #surveyContent {
    padding: 0rem 0rem 0rem 2rem !important;
  }
  #Interviews .surveyContent {
    width: 100% !important  ;
    max-width: 75% !important;
    margin: 0 !important;
  }
  #surveyAnalysis #surveyContent {
    padding: 3rem 0rem 3rem 0rem !important;
  }
  #surveyAnalysis .surveyContent {
    padding: 6.3rem 0rem 0rem 0rem;
    position: relative;
    width: 100%;
    max-width: 89%;
  }
  img.rounded.img-fluid.float-end.surveyillustration {
    width: 100% !important;
    max-width: 44% !important;
    position: relative !important;
    top: 2.1rem !important;
    z-index: 2 !important;
    left: 3rem !important;
  }
  img.rounded.handshakes {
    position: relative;
    top: -22rem;
    width: 100%;
    max-width: 84px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.GraphImg {
    position: relative;
    top: -3rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.solution1 {
    position: relative;
    top: -20.5rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.brainImg {
    position: relative;
    top: -3rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.border1 {
    opacity: 0;
    visibility: hidden;
  }
  img.rounded.artificialIntelligence2 {
    position: relative;
    top: -7.3rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.light {
    position: relative;
    top: 1rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.emojionekeycap2 {
    position: relative;
    top: -21rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.border2 {
    opacity: 0;
    visibility: hidden;
  }
  img.rounded.emojionekeycap3 {
    position: relative;
    top: 0;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  .footer_heading {
    font-size: 20px !important;
  }
}
div#CareerDetail {
  padding: 2rem 0 0 0;
}
div#ApplyNow {
  padding: 2rem 0 0 0;
}

.bgCase5Div .hero-images:nth-child(n + 1) {
  text-align: center;
}
.bgCase5Div .hero-Content h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  font-style: normal;
  line-height: 79px;
  font-family: "Dosis", sans-serif;
}
@media only screen and (max-width: 600px) {
  .QurbaniLearningscontentCard.text-center {
    text-align: center !important;
    padding: 0rem 0 0 0 !important;
  }
  #Qurbanibg-case-about2 {
    background-position: 100% bottom !important;
    background-size: 100% 100% !important;
    padding: 4rem 0 0 0 !important;
    background: #bafdd7 !important;
    background-image: none !important;
  }
  #Qurbanibg-case-about4 {
    background-position: inherit !important;
    background-size: cover !important;
    padding: 4rem 0 0 0 !important;
    background-repeat: no-repeat !important;
    background-color: #f1fffc !important;
    background-image: none !important;
  }
  .QurbaniSurveyTitles {
    padding: 1rem 0 !important;
  }
  #QurbanimainLearnings {
    z-index: 999 !important;
    opacity: 99% !important;
    padding: 10rem 0 12rem 0 !important;
    background-position: 100% bottom !important;
    background-size: 100% 100% !important;
  }
  #QurbaniInterviews .QurbaniInterviewCardImgMain {
    padding: 10rem 0 !important;
  }
  #QurbaniSurveyAnalysis {
    padding: 10rem 0 5rem 0 !important;
  }
  #QurbaniInterviews {
    padding: 0 !important;
    background: #5f5e5e !important;
    position: relative !important;
    background-image: none !important;
  }
  #QurbaniInterviews .QurbaniInterviewsImg {
    padding: 0 !important;
  }
  #bg-case-empathyMap {
    padding: 7rem 0 !important;
  }

  #QurbaniProblemStatement {
    padding: 0 !important;
    background-position: 100% 100% !important;
    text-align: center !important;
    background-size: cover !important;
    position: relative !important;
  }
  #OptimusAbouts .secondAboutContent .aboutText {
    padding: 0rem 0 !important;
  }
  #interviewQSection .interviewQSectionHead {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    width: 100% !important;
    padding: 3rem 0 !important;
    text-align: center !important;
  }
  #CareerDetail #mainJobTitle {
    padding: 1rem 0 1rem 0 !important;
  }
  div#CareerDetail {
    padding: 2rem 0 0 0;
  }
  .btn-group,
  .btn-group-vertical {
    position: relative !important;
    display: inline-flex !important;
    vertical-align: middle !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  #ApplyNow #mainJobTitle {
    padding: 0rem 0 1rem 0 !important;
  }
  div#ApplyNow {
    padding: 2rem 0 0 0 !important;
  }
  #jobs-listings {
    padding: 3rem 0 0 0 !important;
  }
  .pull-right {
    float: left !important;
    padding: 0 0 2% 0 !important;
  }
  .equalWidth {
    width: 100% !important;
    max-width: 100% !important;
  }
  #jobs-listings .list-group-item-subtitle.job-subtitle {
    color: #777777 !important;
    text-align: start !important;
  }
  #CareerDetail #mainJobTitle .jobTitle ul {
    display: block !important;
  }
  #CareerDetail #mainJobTitle .jobApplyBtn {
    float: left !important;
    width: 100%;
  }
  #QurbaniUserPersonas .QurbaniUserPersonasContent2 {
    padding-top: 11rem !important;
    text-align: center !important;
  }
  .bg-shape {
    background-size: cover !important;
    width: 100% !important;
  }
  #QurbaniUserPersonas {
    height: 800px !important;
  }
  #bg-case-1 .StockChainHeader img.rounded.illustrationHealth {
    position: relative !important;
    top: -8.5rem !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 7rem !important;
    left: 0rem !important;
    background-position: 100% center !important;
    background-size: cover !important;
    mix-blend-mode: hard-light;
  }
  #bg-case-1 .StockChainHeader .hero-images .StockLogo1 {
    position: relative !important;
    left: 1rem !important;
    top: 6px !important;
  }
  #StockAbouts .firstAboutContent .aboutText {
    padding: 1rem 0rem !important;
  }
  #bg-case-1 .StockChainHero .StockChainHeroText1 {
    padding: 0 0 12rem 0 !important;
  }
  .StockChainHeroImg {
    position: absolute !important;
    bottom: 18rem !important;
  }
  #bg-case-1 .StockChainHero .StockChainHeroText2 {
    padding: 14rem 0 2.8rem 0 !important;
  }
  #StockProblemStatement .ProblemStatementCard .card .card-title {
    font-size: 22px;
  }
  #StockProblemStatement .ProblemStatementCard {
    padding: 1rem !important;
  }
  #StockAbouts .firstAboutContent {
    padding: 2rem 0 0 0 !important;
  }
  #FooterStockLogo .FooterStockImg img {
    width: 100% !important;
    max-width: 15rem !important;
  }
  .generallyAboutContent {
    padding: 0 !important;
  }
  #OptimusMobileAppDesign .AppDesignMain {
    text-align: center !important;
    margin: -3rem 0 0 0 !important;
  }
  #interviewQSection #questioned {
    padding: 3rem 0 !important;
    text-align: center !important;
  }
  #interviewQSection #questionedMain #questionedImg {
    text-align: center !important;
    padding: 3rem 0 0 0 !important;
  }
  .bg-Case-4_Hero .covidFear2 p {
    padding: 0 1rem !important;
  }
  .bg-Case-4_Hero .covidFear p {
    padding: 0 1rem !important;
  }
  .bg-Case-4_Hero .covidFear {
    padding: 1rem 0 !important;
    background-repeat: no-repeat !important;
    background-image: none !important;
    background-color: #e7e7e7 !important;
  }
  .bg-Case-4_Hero .covidFear2 {
    padding: 1rem 0 !important;
    background-repeat: no-repeat !important;
    background-color: #e7e7e7 !important;
    background-image: none !important;
  }
  img.businessOpportunityIcon {
    width: 100% !important;
    max-width: 100% !important;
  }
  img.SolutionImg {
    width: 100% !important;
    max-width: 100% !important;
  }
  img.problemStatementImg {
    width: 100% !important;
    max-width: 100% !important;
  }
  img.MotivationVector {
    padding: 5rem 7rem;
  }

  img.rounded.illustrationHealth {
    position: relative;
    top: -12.5rem;
    padding: 0;
    width: 100%;
    max-width: 11rem;
    left: 0rem;
    background-position: 100% center;
    background-size: cover;
  }
  #NaseemFooterTop p {
    font-size: 33px !important;
  }
  hr.clearfix.w-100.d-md-none.pb-3 {
    display: none !important;
  }
  #bg-case-about4 {
    background-position: 100% bottom !important;
    background-size: 100% 100% !important;
    padding: 5rem 0 0 0 !important;
    background-repeat: no-repeat !important;
    background-image: none !important;
    background-color: #f4fffc !important;
  }
  #bg-case-about2 {
    background-position: 100% bottom !important;
    background-size: 100% 100% !important;
    padding: 5rem 0 0 0 !important;
    background-color: #e1f2ff !important;
    background-image: none !important;
  }

  #painPoints {
    padding: 0 0 7rem 0 !important;
  }
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.charGroup1 {
    width: 40% !important;
    float: left !important;
    height: 100% !important;
  }
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.charGroup2 {
    width: 40% !important;
    float: right !important;
    height: 100% !important;
    position: relative !important;
    bottom: 44rem !important;
  }
  #surveyAnalysis .surveyAnalysisCard1 {
    padding: 1rem 3rem !important;
  }

  #mainLearnings {
    background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/yellowback2.png") !important;
    padding: 0rem 0 9rem 0 !important;
    background-position: 100% bottom !important;
    background-size: 100% 100% !important;
  }
  .LearningscontentCard.text-center {
    padding: 0rem 0 5rem 0 !important;
  }
  #mainLearnings .LearningsTop {
    position: relative !important;
    margin: -9rem 0 5rem 0 !important;
  }
  img.rounded.img-fluid.float-end.surveyillustration {
    width: 100% !important;
    max-width: 35% !important;
    position: relative !important;
    top: 0.5rem !important;
    z-index: 2 !important;
    left: 0rem !important;
  }

  img.rounded.emojionekeycap1 {
    position: relative;
    top: -2rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.solution1 {
    position: relative;
    top: -30rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.artificialIntelligence2 {
    position: relative;
    top: -3rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.brainImg {
    position: relative;
    top: -2rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.emojionekeycap2 {
    position: relative;
    top: -25rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.handshakes {
    position: relative;
    top: -34rem;
    width: 100%;
    max-width: 84px;
    background-position: 100% center;
    background-size: cover;
  }
  img.rounded.emojionekeycap3 {
    position: relative;
    top: -1rem;
    width: 100%;
    max-width: 64px;
    background-position: 100% center;
    background-size: cover;
  }
}
/* @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ww-50 {
      width: 100%;
    }
  } */

@media only screen and (max-width: 1139px) {
  .no-paddingleft {
    padding-left: 0 !important;
  }
}
/* paddings */

/* margin */
.m-auto {
  margin: auto !important;
}
.m-left {
  margin-left: 5px;
}

.m-top-10 {
  margin-top: 10px;
}
.margin-btm-100 {
  margin-bottom: 100px;
}

.margin-btm-40 {
  margin-bottom: 40px;
}
/* margin */

/* Feature Section */

.feature-img {
  height: 300px;
}

.features-div {
  font-family: "Dosis", sans-serif;
  color: white;
  font-weight: 300;
  padding-top: 10rem;
}

.features-div h1,
.features-div h2,
.features-div h3,
.features-div h4,
.features-div h5,
.features-div h6 {
  color: white;
}

.features-div .font-weight-medium {
  font-weight: 500;
}

.features-div .wrap-features-div {
  margin-top: 60px;
}

.features-div .wrap-features-div .text-box {
  padding: 0 30px;
}

.features-div .wrap-features-div .text-box h3 {
  margin: 10px 0 25px 0;
}

/* .features-div .text-info {
      color: black !important;
  } */

/* .features-div .text-megna {
      color: black;
  } */

.features-div .img-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.3);
  margin-top: 20px;
}

.features-div .btn-info-gradiant {
  background: #188ef4;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #188ef4 0%,
    #316ce8 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#188ef4),
    to(#316ce8)
  );
  background: -webkit-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: -o-linear-gradient(left, #188ef4 0%, #316ce8 100%);
  background: linear-gradient(to right, #188ef4 0%, #316ce8 100%);
}

.features-div .btn-info-gradiant:hover {
  background: #316ce8;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #316ce8 0%,
    #188ef4 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#316ce8),
    to(#188ef4)
  );
  background: -webkit-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: -o-linear-gradient(left, #316ce8 0%, #188ef4 100%);
  background: linear-gradient(to right, #316ce8 0%, #188ef4 100%);
}

.features-div .btn-md {
  padding: 15px 45px;
  font-size: 16px;
}

.order-1 {
  order: 1;
}
/* Feature Section */

/* how it works Section */
.service-1 {
  font-family: "Dosis", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.service-1 .font-weight-medium {
  font-weight: 500;
}

/* .service-1 .bg-light {
    background-color: #f4f8fa !important;
  } */

/* .service-1 .subtitle {
    color: #8d97ad;
    line-height: 24px;
  } */

.service-1 .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.service-1 .wrap-service1-box .card-body {
  padding: 40px;
}
.services-h {
  height: auto !important;
}
.services-img-top {
  top: 190px;
}

/* how it works Section */

/* Authentication Screens */

.form-container {
  height: 100vh;
  display: flex;
  align-items: center;

  justify-content: center;
}
.form {
  width: 320px;
  /* height: 50%; */
  border-radius: 20px;
  border: 1px solid #707070;
}

.form-group {
  width: 420px;
  /* height: 50%; */
  border-radius: 20px;
  border: 1px solid #04b2ae;
  padding-bottom: 50px;
}

.login-logo {
  width: 50%;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-title {
  padding: 20px;

  text-transform: uppercase;
  /* background-color: #04B2AE; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.input-container {
  width: 80% !important;
}

.input-icon {
  width: 20px;
}
/* Authentication Screens */

/* Button */
.progress-btn {
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 12px;
  cursor: pointer;
}
.btn-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #2cdd9b 0%,
    #1dc8cc 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cdd9b),
    to(#1dc8cc)
  );
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
  border: 0px;
}

.btn-success-gradiant:hover {
  background: #1dc8cc;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #1dc8cc 0%,
    #2cdd9b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#1dc8cc),
    to(#2cdd9b)
  );
  background: -webkit-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: -o-linear-gradient(left, #1dc8cc 0%, #2cdd9b 100%);
  background: linear-gradient(to right, #1dc8cc 0%, #2cdd9b 100%);
}

.btn-md {
  padding: 15px 45px;
  font-size: 16px;
}

.btn-danger {
  background: red;

  border: 0px;
}

/* Button */

.dropdown {
  background-color: white !important;
  border-radius: 5px;
}

.dropdown > button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
}

.task-container {
  display: flex;
  width: 100%;
}

.task-assiigned {
  width: 33.33%;
  background-color: #f5f5f5;
  margin-left: 10px;
  padding: 10px;
}

.task-menu {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  img.rounded.Group3 {
    position: relative !important;
    top: -10rem !important;
    width: 100% !important;
    max-width: 20% !important;
    background-position: 100% center !important;
    background-size: cover !important;
  }
  img.rounded.kids19201.float-left {
    max-width: 100%;
    width: 100%;
    position: relative;
    left: 0;
    width: 100%;
  }
  .task-container {
    flex-wrap: wrap;
  }
  .task-assiigned {
    width: 100%;
    margin-bottom: 20px;
  }

  .margin-btm-200 {
    margin-bottom: 200px !important;
  }
}

.task-card {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.slider > div {
  background-color: #e3fffe;
  /* position: relative; */
  /* background-image: url("../../../assets/images/sm-shape.png");
    background-repeat: no-repeat; */
}
.slider-2 > div {
  background-color: #fff8cd;
}

.tabs-border {
  border-bottom: 1px solid #c4c4c4;
}

.tab-button-filled {
  background-color: #04b2ae;
  margin-right: 10px;
  border: none;
  padding: 10px 20px;
}

.tab-button-unfilled {
  background-color: transparent;
  margin-right: 10px;
  border: none;
  padding: 10px 20px;
}

.profile-container {
  widows: 700px;
  background-color: white;
}

.project-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.project-card {
  width: 330px;
  height: 350px;

  background-color: #04b2ae;
  border-radius: 12px;
  text-align: center;
  padding-top: 12px;
  /* padding-bottom: 25px; */
  margin-top: 20px;
  margin-right: 20px;
}
.content-div {
  background-color: #e3f2fd;
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 100%;
  width: 100%;
}

.text-container {
  height: 200px;
}

.project-card-heading {
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}
.project-card-members {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.project-card-btn {
  border-radius: 4px;
  background-color: #04b2ae;
  border: none;
  width: 78px;
  margin-top: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Our process */
/* ******** Our Process****** */
#approach_main {
  padding: 4rem 0;
}
#approach_main .approach_text {
  padding: 0rem 0rem 0rem 14rem;
}
#approach_main .approach_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 76px;
  color: #04b2ae;
}
#approach_main .approach_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  color: #fe800e;
}

#approach_main .approach_image .HeroImg {
  width: 100%;
  max-width: 75%;
}

/* ******** brainstorm Styling *********** */

#brainstorm_main {
  background: rgba(180, 15, 15, 0.06);
}

#brainstorm_main .brainstorm_text {
  padding: 0 8rem;
}
#brainstorm_main .brainstorm_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #f48c49;
}

#brainstorm_main .brainstorm_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}

#brainstorm_main .brainstorm_image {
  position: relative;
  left: -15px;
  padding: 0;
  margin: 0;
}

img.Brainstorm {
  width: 100%;
  max-width: 100%;
}

/* Ideation_main */

#Ideation_main {
  padding: 5rem 0;
  background: rgba(68, 180, 15, 0.06);
}
#Ideation_main .Ideation_text {
  padding: 0rem 5rem 0rem 10rem;
}

#Ideation_main .Ideation_textIcon {
  padding: 0rem 0rem 0rem 11rem;
}
#Ideation_main .Ideation_textIcon p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2b2b2b;
}
#Ideation_main .Ideation_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 76px;
  color: #04b2ae;
}
#Ideation_main .Ideation_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Ideation_main .Ideation_image {
  position: relative;
  margin: 0;
  right: -15px;
  padding: 0;
}

#Ideation_main .Ideation_image .Ideation {
  width: 100%;
  max-width: 100%;
}

/* ******** development Styling *********** */

#development_main {
  background: rgba(180, 154, 15, 0.08);
}

#development_main .development_text {
  padding: 0 8rem;
}
#development_main .development_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #e1d919;
}

#development_main .development_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #2b2b2b;
}

#development_main .development_image {
  position: relative;
  left: -15px;
  padding: 0;
  margin: 0;
}

img.development {
  width: 100%;
  max-width: 100%;
}

/* Feedback_main */

#Feedback_main {
  padding: 5rem 0;
  background: rgba(68, 180, 15, 0.06);
}
#Feedback_main .Feedback_text {
  padding: 0rem 5rem 0rem 10rem;
}

#Feedback_main .Feedback_textIcon {
  padding: 0rem 0rem 0rem 14rem;
}
#Feedback_main .Feedback_textIcon p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Feedback_main .Feedback_text h3 {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 76px;
  color: #005f98;
}
#Feedback_main .Feedback_text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #2b2b2b;
}
#Feedback_main .Feedback_image {
  position: relative;
  margin: 0;
  right: -15px;
  padding: 0;
}

#Feedback_main .Feedback_image .Feedback {
  width: 100%;
  max-width: 100%;
}

/* ********** customization *************** */

#customization_main {
  text-align: center;
  position: relative;
  padding: 4rem 0;
  background: rgba(255, 226, 183, 0.12);
}
#customization_main .customization-text {
}
#customization_main .customization-text h3 {
  padding: 1rem 0 2rem 0;
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  color: #f48c49;
}
#customization_main .customization-text p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  color: #2b2b2b;
  padding: 2rem 0 0 0;
}

#customization_main .customization-text .Customize {
  width: 100%;
  max-width: 50%;
}

#PeopleSitting_main {
  text-align: center;
  /* background-image: url("../../../assets/images/Yellow-back.png"); */
  width: 100%;
  padding: 4rem 0;
}

#footer_main {
  padding: 5rem 0;
}
#footer_main .footer-content {
}
#footer_main .footer-content p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  line-height: 38px;
  text-align: center;
  color: #2b2b2b;
}

@media only screen and (max-width: 600px) {
  #FooterStockLogo {
    padding: 3rem 0 !important;
    overflow: hidden !important;
    /* scroll-behavior: smooth !important; */
    background: #c6f7ff !important;
  }
  #QurbaniSurveyAnalysis .QurbaniSurveyTitles {
    padding: 1rem 0 0 0 !important;
    text-align: center !important;
  }
  #QurbaniSurveyAnalysis .QurbaniSurveyImg {
    padding: 3rem 0 0 0 !important;
    text-align: center !important;
  }
  #QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5028,
  #QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5027,
  #QurbaniSurveyAnalysis .QurbaniSurveyCardImg .Group5026 {
    margin: -1.1rem 0 0 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  #OptimusUserPersonas .OptimusPersonasTitle {
    padding: 2rem 0 !important;
  }
  .analysisCountingImg {
    text-align: center !important;
  }
  #OptimusAbouts .firstAboutContent .aboutgroupImg {
    text-align: inherit !important;
  }
  #qurbaniDo #doSecond #dotopSection2 .section-headings1 h3 {
    font-size: 30px !important;
    background: #ad9992c7 !important;
    padding: 1rem !important;
    width: 100% !important;
    max-width: fit-content !important;
    margin: 1rem 0rem !important;
    font-family: "Dosis", sans-serif !important;
  }
  #qurbaniDo #doFirst #dotopSection h3 {
    font-size: 30px !important;
    background: #ad9992c7 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: -moz-fit-content !important;
    max-width: fit-content !important;
    margin: 0 0rem !important;
    line-height: 41px !important;
    font-style: normal !important;
    font-family: "Dosis", sans-serif !important;
  }
  .features-div {
    font-family: "Dosis", sans-serif !important;
    color: white !important;
    font-weight: 300 !important;
    padding-top: 0rem !important;
  }
  #baraServices {
    position: relative !important;
    border: 20px solid #fff !important;
    top: 0rem !important;
    width: 100% !important;
    max-width: 95% !important;
    background-position: 100% center !important;
    background-size: cover !important;
    z-index: 1 !important;
    margin: auto !important;
  }
  #qurbaniDo #doSecond #dotopSection2 .section-headings2 h3 {
    font-size: 30px !important;
    background: #ad9992c7 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 1rem 0 !important;
    font-family: "Dosis", sans-serif !important;
  }
  #qurbaniDo #dotopSection3 h3 {
    font-size: 30px !important;
    background: #ad9992c7 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    font-family: "Dosis", sans-serif !important;
    margin: 1rem 0 !important;
  }
  #primaryColor {
    width: 100% !important;
    max-width: 45% !important;
    position: relative !important;
    left: 5rem !important;
  }
  #secondaryColor {
    width: 100% !important;
    max-width: 45% !important;
    position: relative !important;
    left: 0rem !important;
    top: 17rem !important;
  }
  #approach_main .approach_text p {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    color: #fe800e;
  }
  #approach_main .approach_text h3 {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 55px;
    color: #04b2ae;
  }
  #mainRow {
    flex-direction: column-reverse;
  }

  #approach_main .approach_text {
    padding: 0rem;
  }
  #approach_main .approach_image .HeroImg {
    width: 100%;
    max-width: 100%;
  }
  #brainstorm_main .brainstorm_text {
    padding: 3rem 1rem;
  }
  #Feedback_main .Ideation_text {
    padding: 3rem 1rem;
  }
  #Ideation_main .Ideation_textIcon {
    padding: 0rem 0rem 2rem 1rem;
  }
  #Ideation_main {
    padding: 0;
    background: rgba(68, 180, 15, 0.06);
  }
  #Ideation_main .Ideation_text h3 {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 76px;
    color: #04b2ae;
  }
  #development_main .development_text {
    padding: 0 1rem;
  }
  #Feedback_main .Feedback_text {
    padding: 0rem 1rem 0rem 1rem;
  }
  #Ideation_main .Ideation_text {
    padding: 0rem 1rem 0rem 1rem;
  }
  .PeopleSitting {
    width: 100%;
  }
}

/* @media only screen and (max-width: 992px) {
    #approach_main .approach_text {
      padding: 0rem 0rem 0rem 5rem;
    }
    #approach_main .approach_text p {
    font-family: "Dosis", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 38px;
      line-height: 45px;
      color: #fe800e;
    }
    #approach_main .approach_text h3 {
      font-family: "Dosis", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 39px;
      line-height: 50px;
      color: #04b2ae;
    }
    #approach_main .approach_text p {
    font-family: "Dosis", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 35px;
      color: #fe800e;
    }
    #brainstorm_main .brainstorm_text {
      padding: 0 5rem 0 2rem;
    }
    #Ideation_main .Ideation_text {
      padding: 0rem 0rem 0rem 5rem;
    }
    #Ideation_main .Ideation_textIcon {
      padding: 0rem 0rem 0rem 5rem;
    }
    #development_main .development_text {
      padding: 0 5rem 0 2rem;
    }
    #development_main .development_text h3 {
      font-family: "Dosis", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 35px;
      line-height: 51px;
      color: #e1d919;
    }
    #Feedback_main .Feedback_text {
      padding: 0rem 0rem 0rem 5rem;
    }
  } */

/* Circle bar */

#innerFooter {
  padding: 5rem 0;
}
#innerFooter h3 {
  font-size: 45px;
  padding: 0.5rem;
  font-family: "Dosis", sans-serif;
}
.rightImg1 {
}
.rightImg2 {
  padding-top: 40rem;
}
.rightImg3 {
  padding-top: 50rem;
}
.rightImg4 {
  padding-top: 50rem;
}
.rightImg1 img,
.rightImg2 img,
.rightImg3 img,
.rightImg4 img,
.leftImg1 img,
.leftImg2 img,
.leftImg3 img {
  width: 100%;
  max-width: 50%;
  background-position: 100% center;
  background-size: cover;
  height: 800px;
}
.leftImg1 {
  padding-top: 40rem;
}
.leftImg2 {
  padding-top: 45rem;
}
.leftImg3 {
  padding-top: 50rem;
}

@media (max-width: 600px) {
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.leftVector,
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.rightVector {
    width: 100% !important;
    height: 100% !important;
    display: none !important;
  }
  #bg-case-empathyMap .empathyMapContent .empathyMapLogo img.kidseight {
    max-width: 65% !important;
    width: 100% !important;
    height: 100% !important;
    margin: auto !important;
  }
  img.Frame81Img {
    width: 100% !important;
    max-width: 60% !important;
    margin: auto !important;
    padding: 2rem 0 !important;
  }
  #MobileAppDesign .desktopDesignContent {
    padding: 4rem 0 !important;
  }
  #MobileAppDesign .mobileDesign {
    position: relative !important;
    top: -12.8rem !important;
  }
  #MobileAppDesign .mobileDesign .mobileDesignBottom {
    padding: 10rem 0 0 0 !important;
  }
  #userPersonas .userPersonasFeatures .ExecutivesCard1 {
    position: relative !important;
    bottom: 6.5rem !important;
  }
  #features-divAnal {
    padding-top: 13rem !important;
  }
  .rightImg1 {
  }
  .rightImg2 {
    padding-top: 5rem;
  }
  .rightImg3 {
    padding-top: 5rem;
  }
  .rightImg4 {
    padding-top: 5rem;
  }
  .rightImg1 img,
  .rightImg2 img,
  .rightImg3 img,
  .rightImg4 img,
  .leftImg1 img,
  .leftImg2 img,
  .leftImg3 img {
    width: 100%;
    max-width: 85%;
    background-position: 100% center;
    background-size: cover;
    height: 700px;
  }
  .leftImg1 {
    padding-top: 5rem;
  }
  .leftImg2 {
    padding-top: 5rem;
  }
  .leftImg3 {
    padding-top: 5rem;
  }
  #innerFooter h3 {
    font-size: 25px;
    padding: 0.5rem;
    font-family: "Dosis", sans-serif;
  }
  #do #doFirst #dotopSection h3 {
    font-size: 30px !important;
    background: rgb(0, 0, 0) !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: fit-content !important;
    margin: auto !important;
  }
  #do #doFirst #secondDiv h3 {
    font-size: 30px !important;
    background: #000 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: fit-content !important;

    margin: 1rem;
  }
  #do #doSecond #dotopSection2 .section-headings1 h3 {
    font-size: 30px !important;
    background: #000 !important;
    padding: 1rem !important;
    width: 100% !important;
    max-width: 90% !important;
    margin: auto !important;
    margin-top: 1rem !important;
  }
  #do #doSecond .undrawPersonal img {
    width: 100% !important;
    max-width: 100% !important;
    top: 1rem !important;
    position: relative !important;
    background-position: 100% center !important;
    background-size: cover !important;
  }
  #do #dotopSection3 h3 {
    font-size: 30px !important;
    background: #000 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: 90% !important;
    margin: auto !important;
    margin-top: 1rem !important;
  }
  #surveyAnalysis .surveyContent {
    padding: 5rem 0rem 5rem 0rem !important;
    position: relative !important;
    text-align: center !important;
    width: 100%;
    max-width: 81%;
  }
  .mainsurveyImg {
    text-align: center !important;
    padding: 1rem 0 1rem 0 !important;
  }
  #surveyAnalysis .surveyCircle .circleTitle {
    padding: 1rem 0 0 0 !important;
    font-size: 25px !important;
    color: #070a09 !important;
  }
  #surveyAnalysis .surveyContent .subTitle {
    padding: 1rem 0 0 0 !important;
    font-size: 28px !important;
    color: #000 !important;
  }
  #surveyAnalysis .surveyContent .title {
    font-weight: 600 !important;
    opacity: 1 !important;
    font-size: 45px !important;
    line-height: 60px !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    color: #000 !important;
    padding: 0.5rem 1rem !important;
  }
  #surveyAnalysis {
    background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/yellowBack.png");
    padding-bottom: 5rem;
    background-position: inherit;
    background-size: cover;
    position: relative;
    bottom: -5rem;
  }

  #problemStatement {
    padding: 1rem;
  }
  .naseemEducation {
    padding: 1rem !important;
  }
  .naseemEducation h3 {
    font-size: 40px;
    line-height: 65px;
    font-weight: 600;
    color: #ffffff;
  }
  #TascsurveyAnalysis .surveyContent {
    padding: 10rem 0rem 0rem 1rem;
    position: relative;
    text-align: center;
  }
  #TascsurveyAnalysis .surveyImg {
    padding: 0;
  }
  #tascDo #doSecond #dotopSection2 .section-headings1 h3 {
    font-size: 30px;
    background: #a1a3c1af;
    padding: 1rem;
    width: 100%;
    max-width: 100% !important;
    margin: auto !important;
  }
  #tascDo #doSecond .undrawPersonal img {
    width: 100%;
    max-width: 100%;
    top: 0rem !important;
    position: relative;
    background-position: 100% center;
    background-size: cover;
  }
  #tascDo #doSecond #dotopSection2 .section-headings2 h3 {
    font-size: 30px !important;
    background: #a1a3c1af !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: auto !important;
  }
  #tascDo #dotopSection3 h3 {
    font-size: 30px !important;
    background: #ad9992c7 !important;
    padding: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: auto !important;
  }
  #tascDo #doFirst #dotopSection h3 {
    font-size: 30px;
    background: #a1a3c1af;
    padding: 0.5rem;
    width: 100%;
    max-width: fit-content;
    margin: auto !important;
    line-height: 41px;
    font-style: normal;
  }
  .bg-case-grey {
    background-color: #951a1a !important;
  }
  #appText1 {
    text-align: center !important;
  }
  #appText1 {
    width: 100% !important;
    max-width: 55% !important;
    margin: auto !important;
  }

  #appImg1 {
    padding: 1rem !important;
    border: 1px solid #fff !important;
    margin: 1rem 0rem !important;
    border-radius: 30px !important;
  }
  #trackingText {
    width: 100% !important;
    max-width: 50% !important;
    text-align: center !important;
  }
  #trackingText2 {
    width: 100% !important;
    max-width: 40% !important;
    text-align: center !important;
  }

  #trackingImg1 {
    padding: 1rem !important;
    border: 1px solid #fff !important;
    margin: 3rem 0 !important;
    border-radius: 47px !important;
  }
  #feedbackDetailText {
    width: 100% !important;
    max-width: 50% !important;
  }
  #supervisorDashboardText {
    width: 100% !important;
    max-width: 50% !important;
    margin: auto;
  }
  #Login1 {
    width: 100%;
    max-width: 25%;
  }
  #naseemEducationtext {
    font-size: 30px !important;
    color: #fff !important;
    text-decoration: underline 4px !important;
  }
  #Annoucements {
    color: #000 !important;
    font-size: 45px !important;
  }
  #userInterfaces {
    padding: 1rem !important;
    border: 1px solid #fff !important;
    margin: 3rem 1rem !important;
    border-radius: 25px !important;
  }
}
footer#NaseemCaseStudyFooter {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/FooterBg.png");
  position: relative;
  background-position: 100% top;
  background-size: cover;
  background-repeat: no-repeat;
}
.NaseemCaseStudyFooterContent {
  padding: 21rem 0 5rem 0;
}

#NaseemFooterTop {
}

#NaseemFooterTop p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 63px;
  text-align: center;

  color: #ffffff;
}
#userInterfaces {
  padding: 1rem;
  text-align: center;
  margin: 3rem 9rem;
  border: 1px solid #fff;
  border-radius: 29px;
}

#naseemEducationtext {
  font-size: 50px;
  color: #fff;
  text-decoration: underline 4px;
}
#Annoucements {
  color: #000;
  font-size: 45px;
}
#supervisorDashboardText {
  width: 100%;
  max-width: 45%;
}
#feedbackDetailText {
  width: 100%;
  max-width: 40%;
}
#Login1 {
  width: 100%;
  max-width: 15%;
}
#trackingImg1 {
  padding: 1rem;
  border: 1px solid #fff;
  margin: 3rem 9rem;
  border-radius: 47px;
}
#trackingText {
  width: 100%;
  max-width: 17%;
  text-align: center;
}
#appText1 {
  width: 100%;
  max-width: 20%;
  margin: auto;
  text-align: center;
}
#trackingText22 {
  width: 100%;
  max-width: 30%;
  margin: auto;
  text-align: center;
}
#appImg1 {
  padding: 1rem;
  border: 1px solid #fff;
  margin: 3rem 9rem;
  border-radius: 47px;
}

#do {
  padding: 5rem 0;
}
#do .doFirst {
}
#do #doFirst #dotopSection h3 {
  font-size: 30px;
  background: rgb(0, 0, 0);
  padding: 0.5rem;
  width: 100%;
  max-width: fit-content;
  margin: 0px 7rem;
  font-weight: "400";
  font-family: "Dosis", sans-serif;
}
#do #doFirst #secondDiv h3 {
  font-size: 28px;
  background: #000;
  padding: 0.5rem;
  width: 100%;
  max-width: fit-content;
  font-family: "Dosis", sans-serif;
  margin: auto;
}

#do #doSecond {
}

#do #doSecond #dotopSection2 .section-headings1 h3 {
  font-size: 30px;
  background: #000;
  padding: 1rem;
  width: 100%;
  max-width: fit-content;
  margin: 0 2rem;
  font-family: "Dosis", sans-serif;
}
#do #doSecond #dotopSection2 .section-headings2 h3 {
  font-size: 30px;
  background: #000;
  padding: 0.5rem;
  width: 100%;
  max-width: fit-content;
  margin: 0 2rem;
  font-family: "Dosis", sans-serif;
}

#do #doSecond {
}
#do #doSecond .undrawPersonal img {
  width: 100%;
  max-width: 100%;
  top: 12rem;
  position: relative;
  background-position: 100% center;
  background-size: cover;
}
#do #dotopSection3 {
  justify-content: center;
}
#do #dotopSection3 h2 {
}
#do #dotopSection3 h3 {
  font-size: 30px;
  background: #000;
  padding: 0.5rem;
  width: 100%;
  max-width: 65%;
  margin: 0 2rem;
  font-family: "Dosis", sans-serif;
}

/* Naseem Do Section */

/*  TASC Do Section */
#tascDo {
  padding: 5rem 0;
}
#tascDo .doFirst {
}
#tascDo #doFirst #dotopSection h3 {
  font-size: 30px;
  background: #a1a3c1af;
  padding: 0.5rem;
  width: 100%;
  max-width: fit-content;
  margin: 0px 7rem;
  line-height: 41px;
  font-style: normal;
  font-family: "Dosis", sans-serif;
}
#tascDo #doFirst #secondDiv h3 {
  font-size: 30px;
  background: #a1a3c1af;
  padding: 0.5rem;
  width: 100%;
  max-width: 100%;
  font-family: "Dosis", sans-serif;
}

#tascDo #doSecond {
}

#tascDo #doSecond #dotopSection2 .section-headings1 h3 {
  font-size: 30px;
  background: #a1a3c1af;
  padding: 1rem;
  width: 100%;
  max-width: fit-content;
  margin: 0 2rem;
  font-family: "Dosis", sans-serif;
}
#tascDo #doSecond #dotopSection2 .section-headings2 h3 {
  font-size: 30px;
  background: #a1a3c1af;
  padding: 0.5rem;
  font-family: "Dosis", sans-serif;
  width: 100%;
  max-width: fit-content;
  margin: 0 2rem;
}

#tascDo #doSecond {
}
#tascDo #doSecond .undrawPersonal img {
  width: 100%;
  max-width: 100%;
  top: 12rem;
  position: relative;
  background-position: 100% center;
  background-size: cover;
}
#tascDo #dotopSection3 {
  justify-content: center;
}
#tascDo #dotopSection3 h2 {
}
.Tasclogistics {
  text-align: center;
  padding: 5rem 0;
}
.Tasclogistics h2 {
  font-size: 50px !important;
  color: #950014;
  font-weight: 700;
}
#tascDo #dotopSection3 h3 {
  font-size: 30px;
  background: #ad9992c7;
  padding: 0.5rem;
  width: 100%;
  max-width: fit-content;
  margin: 0 2rem;
  font-family: "Dosis", sans-serif;
}

/* Naseem Do Section */
#qurbaniDo {
  padding: 5rem 0;
}
#qurbaniDo .doFirst {
}
#qurbaniDo #doFirst #dotopSection h3 {
  font-size: 30px;
  background: #ad9992c7;
  padding: 0.5rem;
  width: 100%;
  max-width: 60%;
  margin: auto;
  line-height: 41px;
  font-style: normal;
  font-family: "Dosis", sans-serif;
}
#qurbaniDo #doFirst #secondDiv h3 {
  font-size: 28px;
  background: #ad9992c7;
  padding: 0.5rem;
  font-family: "Dosis", sans-serif;

  width: 100%;
  max-width: 100%;
}

#qurbaniDo #doSecond {
}

#qurbaniDo #doSecond #dotopSection2 .section-headings1 h3 {
  font-size: 30px;
  background: #ad9992c7;
  padding: 1rem;
  width: 100%;
  max-width: 75%;
  margin: 0 2rem;
}
#qurbaniDo #doSecond #dotopSection2 .section-headings2 h3 {
  font-size: 30px;
  background: #ad9992c7;
  padding: 0.5rem;
  width: 100%;
  max-width: 100%;
  margin: 0 2rem;
}

#qurbaniDo #doSecond {
}
#qurbaniDo #doSecond .undrawPersonal img {
  width: 100%;
  max-width: 100%;
  top: 12rem;
  position: relative;
  background-position: 100% center;
  background-size: cover;
}
#qurbaniDo #dotopSection3 {
  justify-content: center;
}
#qurbaniDo #dotopSection3 h2 {
}
#qurbaniDo #dotopSection3 h3 {
  font-size: 30px;
  background: #ad9992c7;
  padding: 0.5rem;
  width: 100%;
  max-width: 85%;
  margin: 0 2rem;
}

.appdesignMain {
  text-align: center;
}

#primaryColor {
  width: 100%;
  max-width: 45%;
  position: relative;
  left: 10rem;
}
#secondaryColor {
  width: 100%;
  max-width: 45%;
  position: relative;
  left: 10rem;
  top: 13rem;
}

#baraServices {
  position: relative;
  border: 20px solid #fff;
  top: -5rem;
  width: 100%;
  max-width: 70%;
  background-position: 100% center;
  background-size: cover;
  z-index: 1;
}

.flipCard {
  position: inherit;
  width: inherit;
  height: inherit;
  transform-style: preserve-3d;
  transition: 1.5s;
  /* transition: all 0.7s ease; */
}
.flipCard:hover {
  transform: rotateY(180deg);
}
.flipCard .cardFront {
  position: absolute;
  width: inherit;
  height: inherit;
  backface-visibility: hidden;
  color: #000;
}
.flipCard .cardBack {
  position: relative;
  width: inherit;
  height: inherit;
  backface-visibility: hidden;
  color: #000;
  transform: rotateY(180deg);
}

/* *****************  Naseem CaseStudy ***************** */
#problemStatement {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/backgroundstar1.png");
  padding: 15rem 0 0rem 0;
  background-position: inherit;
  background-size: cover;
  position: relative;
  bottom: 20rem;
  margin-bottom: -30rem;
}
#problemStatement #problemStatementContent {
  text-align: center;
  padding: 0 1.9rem;
}
#problemStatement #problemStatementContent h4 {
  background: #5813ec;
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin: auto;
  width: fit-content;
  padding: 0.5rem 2rem;
  line-height: 38px;
  color: #ffffff;
}
#problemStatement #problemStatementContent p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #2b2b2b;
}
.firstSection {
  position: relative;
  top: 5rem;
}
.secondSection {
  position: relative;
  bottom: 5rem;
}
.thirdSection {
  position: relative;
  bottom: 15rem;
}

/* ******** surveyAnalysis ******************* */
#surveyAnalysis {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/yellowBack.png");
  padding-bottom: 5rem;
  background-position: inherit;
  background-size: cover;
  position: relative;
  bottom: -5rem;
}
#surveyAnalysis .surveyContent {
}
#surveyAnalysis .surveyContent p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #97690f;
  background: #ffde9f;
  text-align: center;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #97690f;
  padding: 0.8rem 0;
}
#surveyAnalysis #surveyContent {
  padding: 10rem 4rem 0rem 4rem;
}
#surveyAnalysis #surveyContent p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 43px;
  color: #724f0c;
}
#surveyAnalysis #surveyContent p span {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #724f0c;
}

#surveyAnalysis .surveyAnalysisCard1 {
  background: #ffde9f;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #97690f;
  padding: 0 3rem;
  position: inherit;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 30%);
  border-radius: 0px 75px 75px 0px;
  align-items: center;
}

#surveyAnalysis .surveyAnalysisCard1 .smartPhonesTxt {
}
#surveyAnalysis .surveyAnalysisCard1 .smartPhonesTxt p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #724f0c;
}
#surveyAnalysis .surveyAnalysisCard1 .smartPhonesIcon {
  float: left;
}

/* Cards Number 2 */

#surveyAnalysis .surveyAnalysisCard2 {
  background: #ffde9f;
  border-width: 4px 4px 0px 0px;
  border-style: solid;
  border-color: #97690f;
  padding: 0 3rem;
  position: inherit;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
  border-radius: 75px 0px 0px 75px;
  align-items: center;
}
#surveyAnalysis .surveyAnalysisCard2 .smartPhones {
  display: flex;
  align-items: flex-end;
}
#surveyAnalysis .surveyAnalysisCard2 .smartPhonesTxt p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #724f0c;
}

/* ******** School Executives, Principles, Teachers & Parents. ******************* */
#Interviews {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/brownBack.png");
  padding: 5rem 0 5rem 0;
  background-position: inherit;
  background-size: cover;
  position: relative;
  top: 2.4rem;
}
#Interviews .surveyContent {
  width: 100%;
  max-width: 65%;
  margin: auto;
}
#Interviews .surveyContent p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #97690f;
  background: #ffde9f;
  text-align: center;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #97690f;
  padding: 0.8rem 0;
}
#Interviews #surveyContent {
  padding: 10rem 4rem 0rem 4rem;
}
#Interviews #surveyContent p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #5a3408;
}
#Interviews #surveyContent span {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #5a3408;
}
#Interviews #surveyContent p span {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 33px;
  color: #724f0c;
}

#Interviews .surveyAnalysisCard1 {
  background: #ffde9f;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #97690f;
  padding: 0 3rem;
  position: inherit;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 30%);
  border-radius: 0px 75px 75px 0px;
  align-items: center;
}

#Interviews .surveyAnalysisCard1 .smartPhonesTxt {
}
#Interviews .surveyAnalysisCard1 .smartPhonesTxt p {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: #724f0c;
}
#Interviews .surveyAnalysisCard1 .smartPhonesIcon {
  float: left;
}
.interviewsCard {
  position: relative;
  /* bottom: 10rem; */
  padding: 1rem 0 10rem 0;
}
.interviewsCardContent {
  background: #ffe2bf;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #88561c;
  box-shadow: 0px 10px 30px rgb(0 0 0 / 30%);
  border-radius: 0px;
}
.interviewsCardContent ul {
  display: flex;
  align-items: center;
}
.interviewsCardContent ul .Imgli {
  width: 15%;
}
.interviewsCardContent ul .textli {
  width: 85%;
}
.interviewsCardContent ul li {
  list-style: none;
}
.interviewsCardContent ul li img {
  width: 35px;
  height: 22.58px;
}
.interviewsCardContent ul li p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 51px;
  color: #88561c;
}
#Interviews .Group1475 {
  text-align: end;
  position: relative;
  left: 2.7rem;
  z-index: 99;
}
#Interviews .Group1475 img {
  width: 100%;
  max-width: 122.09px;
}

/* ***************** main Learnings ********************* */

#mainLearnings {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/yellowback2.png");
  /* background-color: #F0A512; */
  padding: 0rem 0 24rem 0;
  background-position: 100% bottom;
  background-size: 100% 100%;
  /* height: 1472px; */
}
#mainLearnings .LearningsTop {
  position: relative;
  margin: -18rem 0 5rem 0;
}
#mainLearnings .LearningscontentCard .starCard {
}
#mainLearnings .LearningscontentCard .StarCard img {
  width: 100%;
  max-width: 735.91px;
}
#mainLearnings .LearningscontentCard .ExecutivesCard {
}
#mainLearnings .LearningscontentCard .ExecutivesCard img {
  width: 100%;
  max-width: 300px;
}

/* ***************** main Learnings ********************* */

#userPersonas {
  background-color: #e1f3ff;
  /* padding: 0rem 0 5rem 0; */
  background-position: 100% bottom;
  background-size: 100% 100%;
  height: auto;
}
#userPersonas .userPersonasFeatures {
}

#userPersonas .userPersonasFeatures .ExecutivesCard1 {
  position: relative;
  bottom: 13.3rem;
}
#userPersonas .userPersonasFeatures .ExecutivesCard1 img {
  width: 100%;
  max-width: 100%;
}
#userPersonas .userPersonasFeatures .ExecutivesCard2 {
  position: relative;
  bottom: 5rem;
}
#userPersonas .userPersonasFeatures .ExecutivesCard3 {
  position: relative;
  top: 0;
}

/* ***************** About1 Sections ********************* */

#bg-case-about1 {
  background-position: 100% 100% 100%;
  background-size: cover;
  padding: 5rem 0;
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/lightpink back.png");
}
#bg-case-about1 .naseemAbout {
}
#bg-case-about1 .naseemAbout .naseemAboutContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#bg-case-about1 .naseemAbout .naseemAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #1384ec;
}
#bg-case-about1 .naseemAbout .naseemAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #1384ec;
}
#bg-case-about1 .naseemAbout .AboutVector {
}
#bg-case-about1 .naseemAbout .AboutVector img {
  width: 100%;
  height: 450px;
}
#bg-case-about1 .naseemAbout .AboutVectorText {
  display: flex;
  flex-direction: column;
  gap:15px
}
#bg-case-about1 .naseemAbout .AboutVectorText .card {
}
#bg-case-about1 .naseemAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #1384ec;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about1 .naseemAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #1384ec;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about1 .naseemAbout .AboutVectorText .card .card-body .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #1384ec;
}
#bg-case-about1 .naseemAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #1384ec;
}

#about2linearBg {
  background-image: linear-gradient(#fff4f4 50%, #fffbf4 50%);
}
#about4linearBg {
  background-image: linear-gradient(#fffbf4 50%, #12bbf0 50%);
}
#empathyMaplinearBg {
  background-image: linear-gradient(#f4fffc 50%, #12bbf0 50%);
  padding-top: 1rem;
}
/* ***************** About2 Sections ********************* */

#bg-case-about2 {
  background-position: 100% bottom;
  background-size: 100% 100%;
  padding: 14rem 0 0 0;
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/aboutBg.png");
}
#painPoints {
  /* padding: 0 0 20rem 0; */
}
#bg-case-about2 .naseemAbout {
}
#bg-case-about2 .naseemAbout .naseemAboutContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#bg-case-about2 .naseemAbout .naseemAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #39433e;
}
#bg-case-about2 .naseemAbout .naseemAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #39433e;
}
#bg-case-about2 .naseemAbout .AboutVector {
}
#bg-case-about2 .naseemAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#bg-case-about2 .naseemAbout .AboutVectorText {
  display: flex;
  flex-direction: column;
  gap:15px
}
#bg-case-about2 .naseemAbout .AboutVectorText .card {
}
#bg-case-about2 .naseemAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #39433e;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about2 .naseemAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #39433e;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about2 .naseemAbout .AboutVectorText .card .card-body .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #39433e;
}
#bg-case-about2 .naseemAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #39433e;
}

/* ***************** About3 Sections ********************* */

#bg-case-about3 {
  background-position: 100% bottom;
  background-size: 100% 100%;
  padding: 1rem 0;
  background-color: #fffbf4;
}
#bg-case-about3 .naseemAbout {
}
#bg-case-about3 .naseemAbout .naseemAboutContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#bg-case-about3 .naseemAbout .naseemAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #d47b01;
}
#bg-case-about3 .naseemAbout .naseemAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #d47b01;
}
#bg-case-about3 .naseemAbout .AboutVector {
}
#bg-case-about3 .naseemAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#bg-case-about3 .naseemAbout .AboutVectorText {
  display: flex;
  flex-direction: column;
  gap:15px;
}
#bg-case-about3 .naseemAbout .AboutVectorText .card {
}
#bg-case-about3 .naseemAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #ff9300;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about3 .naseemAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #ff9300;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about3 .naseemAbout .AboutVectorText .card .card-body .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #d47b01;
}
#bg-case-about3 .naseemAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #d47b01;
  
}
/* ***************** About4 Sections ********************* */

#bg-case-about4 {
  background-position: 100% bottom;
  background-size: 100% 100%;
  padding: 15rem 0 0 0;
  background-repeat: no-repeat;
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/lightgreenBack.png");
}
#bg-case-about4 .naseemAbout {
}
#bg-case-about4 .naseemAbout .naseemAboutContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#bg-case-about4 .naseemAbout .naseemAboutContent h3 {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 51px;
  text-align: start;
  color: #4e7e6d;
}
#bg-case-about4 .naseemAbout .naseemAboutContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  color: #4e7e6d;
}
#bg-case-about4 .naseemAbout .AboutVector {
}
#bg-case-about4 .naseemAbout .AboutVector img {
  width: 100%;
  height: 511.23px;
}
#bg-case-about4 .naseemAbout .AboutVectorText {
  display:flex;
  flex-direction: column;
  gap:15px
}
#bg-case-about4 .naseemAbout .AboutVectorText .card {
}
#bg-case-about4 .naseemAbout .AboutVectorText .card .card-body {
  background: #e1f2ff;
  border-width: 0px 0px 2px 2px;
  border-style: solid;
  border-color: #67a991;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about4 .naseemAbout .AboutVectorText .card .card-title {
  font-family: "Dosis";
  font-style: normal;
  margin: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  background: #67a991;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.25);
}
#bg-case-about4 .naseemAbout .AboutVectorText .card .card-body .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #4e7e6d;
}
#bg-case-about4 .naseemAbout .AboutVectorText .card-text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 33px;
  color: #4e7e6d;
}
/* ***************** bg-case-empathyMap Sections ********************* */

#bg-case-empathyMap {
  background-position: 100% 100%;
  background-size: cover;
  padding: 15rem 0;
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/GreenBack.png");
}
#bg-case-empathyMap .empathyMapContent {
  text-align: center;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo {
  text-align: center;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.charGroup1,
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.charGroup2 {
  width: 246.66px;
  height: 267.81px;
}
#bg-case-empathyMap
  .empathyMapContent
  .empathyMapLogo
  img.charGroup1
  img.handsUnite {
  width: 218px;
  height: 338px;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.doCard {
  width: 350px;
  height: 293px;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.leftVector {
  width: 100%;
  height: 100%;
  position: relative;
  left: 3.7rem;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.rightVector {
  width: 100%;
  height: 100%;
  position: relative;
  right: 2.4rem;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.seeCard,
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.painCard {
  width: 350px;
  height: 262px;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.kidseight {
  width: 100%;
  height: 100%;
}
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.seeCard,
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.painCard,
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.hearCard,
#bg-case-empathyMap .empathyMapContent .empathyMapLogo img.gainCard {
  width: 350px;
  height: 262px;
}
/* ***************** Wire frames Content ***************** */
#Sketches {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/RectangleBlueBg.svg");
  padding: 5rem 0;
}
#Sketches .SketchesContent {
}
#Sketches .SketchesContent .mobileCommomImg img {
  width: 100%;
  height: 100%;
  z-index: 999;
}
#Sketches .mobileDesignBottom {
  padding-top: 0;
}
#Sketches .mobileDesignBottom .mobileCommomImg {
  width: 100%;
  height: 100%;
  z-index: 999;
}
#Sketches .mobileDesignTop {
  padding-top: 10rem;
}
#Sketches .mobileDesignTop .mobileCommomImg {
  width: 100%;
  height: 100%;
  z-index: 999;
}

/* ***************** Wire frames Content ***************** */
#Wireframes {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/RectangleGreyBG.png");
}
#Wireframes .WireframesContent {
}
#Wireframes .WireframesContent img.Frame81Img {
  padding: 3rem 0;
}
#Wireframes .WireframesContent .mobileCommomImg img {
  width: 100%;
  height: 100%;
  z-index: 999;
}
#Wireframes .mobileDesignBottom {
  padding-top: 0;
}
#Wireframes .mobileDesignBottom .mobileCommomImg {
  width: 100%;
  height: 100%;
  z-index: 999;
}
#Wireframes .mobileDesignTop {
  padding-top: 10rem;
}
#Wireframes .mobileDesignTop .mobileCommomImg {
  width: 100%;
  height: 100%;
  z-index: 999;
}

/* ***************** Naseem App Design ***************** */

#MobileAppDesign {
  background-image: url("https://infini8ai-images.s3.amazonaws.com/naseem-casestudy/Rectangle163.svg");
}

#MobileAppDesign .desktopDesignContent {
  padding: 7rem 0;
}
#MobileAppDesign .desktopDesignContents {
  padding: 0 0 7rem 0;
}
#MobileAppDesign .mobileDesign .mobileDesignBottom {
  padding-top: 10rem;
}
#MobileAppDesign .mobileDesign .mobileDesignTop {
  padding-top: 0;
}
#MobileAppDesign .mobileDesign .mobileCommomImg img {
  width: 100%;
  height: 100%;
  z-index: 999;
}
#MobileAppDesign .mobileDesign .mobileBottomImg {
  width: 100%;
  max-width: 90%;
  margin: auto;
  position: relative;
  top: -45px;
}

#MobileAppDesign .mobileDesign .mobileTopImg {
  width: 100%;
  max-width: 90%;
  margin: auto;
  position: relative;
  top: 12rem;
}
#MobileAppDesign .mobileDesign {
  position: relative;
  top: -14rem;
}

/* ************************ jobs-listings *********************** */

#jobs-listings {
  padding: 5rem 0 0 0;
}
#jobs-listings .heading {
  font-size: 18px;
  font-weight: 700;
  padding: 1% 0;
  cursor: default;
  text-transform: uppercase;
}
#jobs-listings .heading:hover {
  font-size: 18px;
  font-weight: 700;
  padding: 1% 0;
  color: #00b5b1;
  text-transform: uppercase;
}
#jobs-listings .panel-jobboard {
  border: 0;
}
#jobs-listings .panel {
  margin-bottom: 18px;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1pxrgba (0, 0, 0, 0.05);
}
#jobs-listings .panel > .list-group,
#jobs-listings .panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
#jobs-listings .list-jobs .list-group-item-title {
  font-weight: 400;
  line-height: inherit;
  margin-bottom: 0;
  cursor: default;
}
#jobs-listings .list-group-item-subtitle.job-subtitle {
  color: #777777;
  text-align: end;
}
#list_group_link {
  border: none;
  text-decoration: none;
  padding-top: 0.8%;
  padding-bottom: 0.8%;
}
#list_group_link:hover {
  border: none;
  background-color: #c1c1c11c;
  text-decoration: none;
  color: #00b5b1;
}

#jobs-listings .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

#jobs-listings .btn-inverse-alt {
  background-color: transparent;
  border-color: #4f5259;
  border-width: 1px;
  box-shadow: none;
  border-style: solid;
  color: #333;
}
#jobs-listings .btn-inverse-alt:hover {
  background-color: #00b5b1;
  border-color: #00b5b1;
  border-width: 1px;
  box-shadow: none;
  border-style: solid;
  color: #fff;
}
#outlineapplynow {
  background-color: none;
  border: 1px solid #00b5b1;
  color: #00b5b1;
}
#outlineapplynow:hover {
  background-color: #00b5b1;
  border: 1px solid #00b5b1;
  color: #ffffff;
}

/* ************************ CareerDetail Styling *********************** */
#CareerDetail .BackIcon {
  padding: 1rem 0;
  float: left;
}
#CareerDetail .BackIcon ul {
  display: flex;
  padding: 0;
  margin: 0;
}
#CareerDetail .BackIcon ul li {
  list-style: none;
}
#CareerDetail .BackIcon ul li i {
}
#CareerDetail .SocialMediaIcon {
  padding: 1rem 0;
  float: right;
}
#CareerDetail .SocialMediaIcon ul {
  display: flex;
  padding: 0;
  margin: 0;
}
#CareerDetail .SocialMediaIcon ul li {
  list-style: none;
  margin-left: 0.5rem;
}
#CareerDetail .SocialMediaIcon ul li a {
}
#CareerDetail .SocialMediaIcon ul li a img {
  width: 100%;
  max-width: 100%;
}

#CareerDetail #mainJobTitle {
  padding: 3rem 0 1rem 0;
}

#CareerDetail #mainJobTitle .jobTitle {
  list-style: none;
}
#CareerDetail #mainJobTitle .jobTitle h2 {
  font-size: 1.7769230769em;
  font-weight: 600;
  text-transform: none;
}
#CareerDetail #mainJobTitle .jobTitle ul {
  display: flex;
  padding: 0;
}
#CareerDetail #mainJobTitle .jobTitle ul li {
  list-style: none;
  margin-right: 3%;
}
#CareerDetail #mainJobTitle .jobTitle ul li i {
  color: #00b5b1;
}
#CareerDetail #mainJobTitle .jobApplyBtn {
  float: right;
}

#jobDescription {
  padding: 0 0 1rem 0;
}
.equalWidth {
  width: 100%;
  max-width: 70%;
}
/* ************* applynow **************** */

label {
  font-size: 15px;
  font-weight: normal;
}
#ApplyNow #mainJobTitle {
  padding: 3rem 0 1rem 0;
}
#ApplyNow #mainJobTitle .jobTitle {
  list-style: none;
}
#ApplyNow .BackIcon {
  padding: 1rem 0;
  float: left;
}
#ApplyNow .BackIcon ul {
  display: flex;
  padding: 0;
  margin: 0;
}
#ApplyNow .BackIcon ul li {
  list-style: none;
}
#ApplyNow .jobTitle ul {
  padding: 0;
}
#ApplyNow .jobTitle ul li {
  list-style: none;
  padding: 0.5% 0;
}
.btn-group .btn-primary {
  color: #fff;
  background-color: #0077b5;
  border-color: #00669c;
}
.btn-group .btn-primary:hover {
  color: #fff;
  background-color: #005582;
  border-color: #003e5e;
}

.btn-group .btn-secondary {
  color: #fff;
  background-color: #ff5500;
  border-color: #e64d00;
}
.btn-group .btn-secondary:hover {
  color: #fff;
  background-color: #cc4400;
  border-color: #a83800;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}
.legendHeading {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 18px;
  font-size: 19.5px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  max-width: 31%;
}
#applySubmit {
  cursor: pointer;
  background: #00b5b1;
  border: #00b5b1;
}
#applySubmit:hover {
  cursor: pointer;
  background: #00b5b2c0;
  border: #00b5b2c0;
}

#applyCancel {
  cursor: pointer;
  background: none;
  text-decoration: none;
  color: #000;
  border: none;
}
#applyCancel:hover {
  cursor: pointer;
  background: none;
  text-decoration: underline;
  color: #000;
  border: none;
}

/* *************************************************** */
#CareerFooter {
}
#CareerFooter .footer {
  padding-bottom: 30px;
}
#CareerFooter hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
#CareerFooter .footer .watermark {
  color: #777777;
  display: inline-block;
  font-family: "Trebuchet MS", "Lucida Grande", sans-serif;
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 22px;
  text-decoration: none;
  align-items: center;
  transition: color 0.1s ease-out;
}

#CareerFooter .footer .watermark .nav-logo {
  width: 100%;
  max-width: 10%;
  background-size: cover;
  background-position: 100% 100%;
  height: 50px;
}

/* ********************** Get a Quote ************************** */

#GetaQuote {
  background-color: #e5e5e5;
  padding: 5rem 0;
}
#GetaQuote .QuoteSideContent1,
#GetaQuote .QuoteSideContent2,
#GetaQuote .QuoteSideContent3 {
}
#GetaQuote .QuoteSideContent1 ul,
#GetaQuote .QuoteSideContent2 ul,
#GetaQuote .QuoteSideContent3 ul {
  display: flex;
  padding: 0;
}
#GetaQuote .QuoteSideContent1 ul li,
#GetaQuote .QuoteSideContent2 ul li,
#GetaQuote .QuoteSideContent3 ul li {
  list-style: none;
}
#GetaQuote .QuoteSideContent1 ul li a,
#GetaQuote .QuoteSideContent2 ul li a,
#GetaQuote .QuoteSideContent3 ul li a {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #04b2ae;
}

#GetaQuote .GetaQuoteTextContent {
  padding: 1rem 0;
}
#GetaQuote .GetaQuoteTextContent #getStarted {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #1a1a1a;
}
#GetaQuote .GetaQuoteTextContent #getStarted span {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #04b2ae;
}
#GetaQuote .GetaQuoteTextContent p {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #1a1a1a;
}

#GetaQuote .GetaQuoteTextContent .btn-danger {
  color: #fff;
  background-color: #04b2ae;
  border-color: #04b2ae;
}
#GetaQuote .GetaQuoteTextContent .btn-danger:hover {
  color: #fff;
  background-color: #0b918d;
  border-color: #0b918d;
}
.covidFear_mobile {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */

  color: #1a1a1a;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.aggregate_mobile {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  background: linear-gradient(
    90deg,
    #ffffff -24.08%,
    #e7e7e7 50.07%,
    #ffffff 124.23%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.iterview_mobile {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #1a1a1a;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.user_person_mble {
  width: 100%;
  height: 650px;

  background: #1a1a1a;
}
.aboutText_mobile {
  font-family: "Bitter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  color: #1a1a1a;

  text-shadow: 0px 4px 40px rgba(255, 255, 255, 0.5);
}
.empathy_rectangle {
  width: 100%;
  background: #1a1a1a;
}
/* ==========StockChain Mobile========== */
.stockmobile_background1 {
  background: #005bff;
  height: 325px;
}

.stockChain_Text h3 {
  padding: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  text-align: center;
}
.stockChain_Text p {
  /* padding: 10px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* text-align: center; */
}
.stockmobile_background2 {
  background: #00dbff;
  padding: 30px;
}
/* ==========QurbaniMobile========== */
.qurbani_text p {
  font-family: "Roboto";
}
.qurbani_text h3 {
  font-family: "Roboto";
}
.bg-optimusmobile {
  /* background-image: url("../../../assets/images/Optimusmobile/optimus_back.svg"); */
  /* padding: 22px 100px 0rem 153px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10px;

  /* background-position: 100% 100%;
  background-repeat: no-repeat; */
}


/* .problem-statement-img {
  max-width: 100%;
} */

.businesspal-top-img {
  right:229px
}
@media (min-width: 768px) and (max-width: 992px) {
  .businesspal-top-img {
   right:300px
  }
}

.businesspal-img {
  max-width: 165%;
}
@media (min-width: 768px) and (max-width: 992px) {
  .businesspal-img {
    max-width: 300%;

  }
}

.u-img {
  top:50px
}
@media (min-width: 768px) and (max-width: 992px) {
  .u-img {
 
    top:90px
  }
}

.businesspal-first-container {
  min-height: 1200px;
  }
@media  (max-width: 576px) {
  .businesspal-first-container {
  min-height: 970px;
  }
}

