.main_box {
  min-height: 100vh;
  background: #d6f1ef;
}

.meeting_box {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  background: #ffffff;
}
.join_btn {
  width: 117px;
  height: 51px;

  background: #1fcac5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;

  color: #ffffff;

  /* Inside auto layout */
}
