@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Roboto");

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: "Dosis";
}

li {
  vertical-align: middle;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

.nav_right ul {
  gap:7px
}
@media (min-width:992px) {
  .nav_right ul {
    gap:10px
  }
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
#inputID::placeholder {
  color: grey !important;
}
input,
select {
  vertical-align: middle;
}

.text_yellow {
  color: #ffda07;
}
.text_orange {
  color: #ff7a01;
}
.text_gray {
  color: #a0a0a0;
}
.text_blue {
  color: #04b2ae;
}
.text_dark_blue {
  color: #005f98;
}

.btn_blue {
  width: 132px;
  height: 60px;
  color: white;
  border: none;
  border-radius: 4px;
  background: #04b2ae;
  margin-bottom: 20px;
}
.btn_blue a {
  color: #fff;
}
.btn_blue:hover {
  background: linear-gradient(
    90deg,
    #ec7201 0%,
    #ff7a01 50.42%,
    #ec7201 101.89%
  );
  box-shadow: 0px 0px 12px;
  color: #fff;
}
.nav_bar {
  height: 70px;
  color: #6b6b6b;
  list-style: none;
  text-decoration: none;
  width: 100%;
}
.shadow_box {
  width: 100%;
  /* background-color: gray; */
  /* box-shadow: 0px 0px 0px 1px rgb(186, 192, 194); */
}
.nav_bar ul > li a {
  text-decoration: none;
  color: #6b6b6b;
}
.mobile_nav ul li a {
  text-decoration: none;
  color: #6b6b6b;
}
.mobile_nav ul li a:hover {
  text-decoration: none;
  color: #ff7a01;
}
.nav_bar ul > li a:hover {
  text-decoration: none;
  color: #ff7a01;
}
.list_style_none {
  list-style: none;
}
.dropdown-menu.show {
  width: 10% !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  margin-left: -20px;
}

.get_a_quote_btn button {
  background: #fffbf8;
  border: 0px solid #08a6a3;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #08a6a3;
  width: 5rem;
}
.get_a_quote_btn :hover {
  background-color: #ff7a01;
  color: #ffffff;
  background-size: cover;
}
.get_a_quote_btn a {
  text-decoration: none;
  color: white;
}
.hero_section_parent {
  min-height: 1100px;
}
.hero_section {
  position: absolute;
  min-height: 944px;
  background: linear-gradient(180deg, #04b2ae 0%, #038582 100%);
}
.bottom_layer {
  height: 400px;
  max-height: 350px;
  margin-bottom: 110px;
}

.hero_bottom {
  height: 350px;
  margin-bottom: 80px;
}
.our_process {
  margin-top: -150px;
  padding-top: 70px;
  /* background: linear-gradient(
    180deg,
    #fffaf5 0%,
    rgba(255, 250, 245, 0) 79.81%
  ); */
  background-color: #fffaf5;
}

.our_process_text {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.025em;

  color: #000000;
}
.cards_detail {
  min-height: 350px;
  width: 100%;
  background: #fff;
  padding: 20px 0px;
  background-color: #f6f6f6;
  padding-top: 70px;
  padding-bottom: 140px;
}
.cards_detail .container {
  min-height: 220px;
}
.cards_detail .container .row {
  min-height: 220px;
}

.service_we_offer {
  /* margin-top: 50px; */
}

.sevices-img {
  max-width: 100% ;
    }

    @media(min-width:1400px) {
      .sevices-img {
        max-width: 70% ;
          } 
    }

.development {
  min-height: 350px;
  /* border-width: 2px 0px; */
  border-style: solid;
  border-color: #e3f9f7;
  border-radius: 0px;
  position: relative;
  padding-top: 110px;
  padding-bottom: 150px;
}

.development .rectangle {
  background: #e3f9f7;
  border-radius: 175px 0px 0px 175px;
  height: 350px;
  position: absolute;
  width: 52%;
  right: 0;
  z-index: -1;
}
.development .container .row {
  height: 350px;
}

.products {
  /* height: 500px; */
  /* background: linear-gradient(
    180deg,
    #f0f9ff 0%,
    rgba(240, 249, 255, 0) 127.68%
  ); */
  background-color: #fffaf5;
  padding-top: 100px;
  padding-bottom: 100px;
}

.cards_detail .process_cards :nth-child(3) {
  display: none;
  margin-bottom: 0;
}
.process_cards {
  background-color: #fff;
}
.blue_icon {
  width: 28px;
  height: 27px;
  background: #cbe8fa;
  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #005f98;
  border-radius: 4px;
  z-index: -1;
  margin-left: -13px;
}
.empCards {
  height: 150px;
  width: 220px;

  border-radius: 4px;
  background: #f8f8f8;
}
.items {
  height: 36px;
  width: 200px;
  border-radius: 4px;
  padding: 6px 51px 6px 10px;
  background-color: linear-gradient(0deg, #c7c7c7, #c7c7c7),
    linear-gradient(0deg, #ffffff, #ffffff) !important;
  border: 1px solid #c7c7c7;
  color: #6e6b7b;
}
.viewProject {
  height: 36px;
  width: 200px;
  border-radius: 4px;
  padding: 6px 51px 6px 10px;
  background-color: linear-gradient(0deg, #c7c7c7, #c7c7c7),
    linear-gradient(0deg, #ffffff, #ffffff) !important;
  border: 1px solid #c7c7c7;
  color: #6e6b7b;
}
.viewProject:hover {
  border: 2px solid #c7c7c7;
}
.reportLabel {
  height: 30px;
  width: 300px;
  border-radius: 2px;
  background: #018786;
}
.projectCards {
  height: 100px;
  width: 150px;
  border-radius: 3px;
}
.bg-color {
  background: #e9f1f1;
  width: 300px;
}
.textLight {
  color: #ffffff !important;
}
.empText {
  color: #018786;
}
.leaveCard {
  height: 67px;
  width: 322px;
  border-radius: 3px;
  background: #f8f8f8;
}
.leaveRow {
  height: 55px;
  background: #f8f8f8;
  border-radius: 3px;
}
.empBtn {
  height: 32px;
  width: 80px;
  border-radius: 4px;
  padding: 6px 10px 0px 15px;
  color: #6e6b7b;
}
.fc .fc-col-header-cell-cushion {
  color: #000000 !important;
  text-decoration: none;
}
.fc .fc-daygrid-day-number {
  color: #000000 !important;
  text-decoration: none;
}
.empBtn:hover {
  box-shadow: 1px 1px 1px 1px grey;
  color: #6e6b7b;
}
.empBtn:active {
  background: #a3a3a3;
  color: #ffffff;
}
.items:hover {
  color: #018786 !important;
  border: 1px solid #018786;
}
.items:active {
  background: #018786;
  color: #ffffff !important;
}
.empCards:hover {
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8),
    linear-gradient(0deg, #ffcea1, #ffcea1);
  border: 1px solid #ffcea1;
}
.cards {
  /* min-height: 918.97px; */
  margin-top: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cards .ipad {
  margin-top: -120px;
  margin-right: -115px;
}
.cards .mobile {
  margin-top: -180px;
}

.optimus_btn {
  width: 120px;
  height: 40px;
  background: #eeeeee;
  font-weight: 700;
  border-radius: 4px;
}
.qurbani_btn {
  width: 120px;
  height: 40px;
  background: #eeeeee;
  font-weight: 700;
  border-radius: 4px;
  /* margin-top: 230px;  */
  height: 4rem; /* Default margin for larger screens */
}

@media (max-width: 768px) {
  .qurbani_btn {
    width: 120px;
    height: 40px;
    background-color: #eeeeee; /* Use background-color for consistency */
    font-weight: 700;
    border-radius: 4px;
    /* margin-top: 10px; */
    height: 3rem; /* Adjusted margin for smaller screens */
  }
}

@media (min-width: 768px) {
  .qurbani_btn {
    width: 120px;
    height: 40px;
    background-color: #eeeeee; /* Use background-color for consistency */
    font-weight: 700;
    border-radius: 4px;
    /* margin-top: 10px; */
    height: 3rem; /* Adjusted margin for smaller screens */
  }
}

.naseem_btn {
  width: 120px;
  height: 40px;
  background: #eeeeee;
  font-weight: 700;
  border-radius: 4px;
}
.stock_btn {
  width: 120px;
  height: 40px;
  background: #eeeeee;
  font-weight: 700;
  border-radius: 4px;
}
.qurbani_btn:hover {
  background: #04b288;
  color: #fff;
}
.optimus_btn:hover {
  background: #000000;
  color: #fff;
}
.naseem_btn:hover {
  background: #5e18f1;
  color: #fff;
}
.stock_btn:hover {
  background: #005bff;
  color: #fff;
}
.btn_outline_gray {
  border: 1px solid #000000;
  color: black;
}

.btn_outline_green {
  border: 1px solid #04b288;
  color: #04b288;
}

.btn_outline_purple {
  border: 1px solid #5e18f1;
  color: #5e18f1;
}
.btn_outline_blue {
  border: 1px solid #005bff;
  color: #005bff;
}

.card1 {
  background: linear-gradient(180deg, #005f98 0%, rgba(0, 95, 152, 0) 97.22%);
}
.card2 {
  background: linear-gradient(180deg, #f2fcfa 0%, rgba(4, 178, 136, 0) 97.22%);
}
.card3 {
  background: linear-gradient(180deg, #5e18f1 0%, rgba(25, 60, 242, 0) 97.22%);
}
.card4 {
  background: linear-gradient(180deg, #005bff 0%, rgba(0, 91, 255, 0) 97.22%);
  /* margin-bottom: 100px; */
  padding-bottom: 100px;
}

footer {
  /* min-height: 600px; */
  background: linear-gradient(180deg, #04b2ae 0%, #038582 100%);
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .mobile_nav {
    position: absolute;
    z-index: 99;
    width: 100%;
    left: 0;
    top: 71px;
    text-align: center;
    background-color: white;
  }
  .mobile_nav ul li {
    padding: 10px 0px;
  }
  .open_menu {
    display: block;
  }
  .close_menu {
    display: none;
  }

  .service_we_offer_mobile {
    width: 100%;
    background: #f5ffff;
    /* border-width: 2px 0px; */
    border-style: solid;
    border-color: #04b2ae;
    border-radius: 8px;
    padding: 10px;
  }
 
  /* .service_offer_text {
    width: 100%;
    height: 100px;

    font-family: "Dosis";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;

    color: #000000;
  } */
  .cards .ipad {
    margin-top: 0px;
    width: 100%;
    height: 400px;
  }
  .cards_detail {
    background-color: white;
  }
  .cards_detail .process_cards {
    background: #04b2ae !important;
    border-width: 1.29818px 1.29818px 0px 1.29818px;
    border-style: solid;
    border-color: #006664;
    border-radius: 3.89453px;
  }
  .cards_detail .process_cards :first-child {
    color: #ffda07 !important;
  }
  .cards_detail .process_cards :nth-child(2) {
    color: white !important;
  }
  .cards_detail .process_cards :nth-child(3) {
    display: block;
    max-width: 170px;
    margin: auto;
    margin-bottom: -100px;
  }
  .cards_row :nth-child(3) {
    margin-top: 30px !important;
  }
  .cards_row :nth-child(4) {
    margin-top: 50px !important;
  }
}
.hero_img {
  height: 680px;
  z-index: 0;
  width: 65%;
  left: 44%;
  order: 2;
}
.hero_img img {
  max-width: 95%;
}
@media (min-width: 1400px) {
  .hero_img img {
    max-width: 75%;
  }
}
@media (max-width: 768px) {
  .naseem-logo-top {
    display: flex;
    justify-content: center;
  }
}
.naseem_logo {
  margin-left: 20%;
  max-width: 50%;
}
@media (max-width: 768px) {
  .naseem_logo {
    margin-left: 20%;
    max-width: 20%;
    max-height: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
.hero-top {
  display: flex;
}

@media (max-width: 768px) {
  .hero-top {
    display: flex;
    flex-direction: column;
    height: 850px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .hero-top {
    padding-left: 2rem;
    padding-right: 7rem;
    height: 950px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .hero-top {
    padding-left: 2rem;
    padding-right: 7rem;
    height: 800px;
  }
}

@media (min-width: 992px) {
  .hero-top {
    padding-left: 2rem;
    padding-right: 7rem;
    height: 600px;
  }
}
.hero_img {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 85px;
}
.container-text {
  max-width: 50%;
  order: 1;
  margin-top: 4rem;
}

@media only screen and (max-width: 768px) {
  .container-text {
    max-width: 100%;
    order: 2;
    margin-bottom: 4rem;
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .hero_img {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 1;
  }
}
@media only screen and (max-width: 768px) {
  .dropdown-menu.show {
    width: 10% !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .hero_img {
    position: static !important;
    height: 100% !important;
    display: block;
    justify-content: center;
    width: 100%;
    padding-top: 0px;
    margin-top: 50px;
  }

  .hero_section_parent p,
  h2 {
    text-align: center;
    padding-top: 10;
  }
  .hero_section {
    min-height: 940px;
  }
  .hero_bottom {
    margin-bottom: 140px;
  }
  .bottom_layer {
    margin-bottom: 160px;
    width: 100% !important;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .heros-text {
    margin-top: 70px;
  }
  @media (max-width: 768px) {
    .heros-text {
      margin-top: 0px;
    }
  }
  .heros-text p,
  h2 {
    padding: 15px;
  }
  .hero_text1 {
    font-size: 17px !important;
    line-height: 20px;
  }
  .cards .mobile {
    margin-top: 0px;
  }

  .cards .health {
    height: 200px;
  }
  .mask-img {
    /* Default styles */
  }
  @media (min-width: 768px) {
    .mask-img {
      margin-top: 20px;
      max-width: 20%;
    }
  }
  @media (max-width: 768px) {
    .cards .business-product-top {
      max-width: 80%;
      display: flex;
      justify-content: flex-end;
    }
  }
  @media (max-width: 768px) {
    .cards .business {
      max-width: 80%;
    }
  }

  .cards .health {
    width: 100% !important;
  }
  .tech-eye {
    margin-bottom: 150px;
    /* width: 50%; */
    margin-top: 0px;
  }
  .tech_eye_text {
    margin-top: -157px;
  }

  .optimus_btn {
    margin-top: 230px;
  }
  .naseem_btn {
    margin-top: 230px;
  }
  .stock_btn {
    margin-top: 230px;
  }
  .naseem_logo {
    margin-left: 0;
  }
}

.alien-img-home {
  max-width: 16%;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .alien-img-home {
    max-width: 20%;
    margin-top: 25px;
  }
}
.process_cards:hover {
  /* background-color: white; */
  /* cursor: pointer; */
  background: #04b2ae !important;
  /* border-width: 1.29818px 1.29818px 0px 1.29818px; */
  border-style: solid;
  border-color: #006664;
  border-radius: 3.89453px;

  color: white !important;

  /* display: block;
  max-width: 170px; */
}
.cards_detail .process_cards:hover :first-child {
  color: #ffda07 !important;
}
.cards_detail .process_cards:hover :nth-child(2) {
  color: white !important;
}
.cards_detail .process_cards :nth-child(3) {
  display: block;
  max-width: 170px;
  margin: auto;
  margin-bottom: -100px;
}
@media only screen and (max-width: 600px) {
  .tech-eye {
    width: 80%;
  }
  .card_btns {
    margin-top: 50px;
  }
  .card4 {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .cards_detail .process_cards :nth-child(3) {
    display: block;
    /* max-width: 170px; */
    margin: auto;
    margin-bottom: -30px;
  }
  .card_btns {
    margin-top: 250px;
    /* margin-bottom: 100px; */
  }
  .cards {
    margin-top: 0;
  }

  .cards .ipad {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    height: 180px;
  }
  .prod_head {
    font-size: 10px;
  }
}
/* ===========Web-portal======== */
main {
  width: 100%;
  /* padding: 10px; */
  background-color: "black";
}
.main_content {
  background: #f5f5f5;
}
/* .sidebar {
  transition: all 0.5s;
  min-height: 100vh;
  width: 250px;
  top: 0;
  left: 0;
  background-color: #018786;
  overflow: visible;
} */

.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
.link {
  display: flex;
  color: #fff;
  padding: 5px 30px;
  transition: all 0.5s;
}
.link:hover {
  background: #10a09f;
  color: #fff;
  transition: all 0.5s;
}
/* .active {
  background: 000;
  color: #fff;
} */
.icon,
.link_text {
  color: #fbf9f9;
  width: 20%;
  font-size: 14px;
  padding: 0px 10px 1px 0px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  align-self: center;
  display: flex;
  margin: 6px;
}
#left-sidebar {
  /* width: 200px; */
  height: 98vh;
  /* float: left; */
  margin-top: 0;
  /* position: fixed; */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 9;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#main-content {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: calc(100%);
  float: right;
  position: relative;
  margin-top: 0px;
  background-color: #eee !important;
  border-color: #eee !important;
  padding: 0px;
}
.sidebar-nav {
  margin: 0 11px;
}
.sidebar-nav ul {
  list-style: none;
}
.sidebar-nav .metismenu {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidebar-nav .metismenu > li {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.active_li {
  background-color: #10a09f !important;
}
.sidebar-nav .metismenu > li .active a {
  font-weight: 700;
}

.sidebar-nav .metismenu a {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #fbf9f9;
  border-radius: 0.1875rem;
  position: relative;
  padding: 6px 40px;
  outline-width: 0;
  width: 230px;
  display: flex;
  align-items: center;
}
.sidebar-nav .metismenu ul a {
  color: #fbf9f9;
  font-size: 16px;
  padding: 10px 15px 10px 47px;
  position: relative;
}
.sidebar-nav .metismenu ul a::before {
  /* content:url('../images/Admin-portal-svg/double-arrow-icon.svg'); */
  position: absolute;
  left: 19px;
}
.sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 70px;
}
.sidebar-nav .metismenu ul.collapse a:hover,
.sidebar-nav .metismenu ul.collapse a:focus {
  text-decoration: none;
}
.scroll {
  max-height: 600px;
  overflow-y: auto;
}
.img_avatar {
  border-radius: 5%;
  align-items: center;
  resize: contain;
  vertical-align: middle;
  width: 70px;
  height: 70px;
  padding: 5px;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(10px, -6px) scale(0.7);
}

.profile-pic {
  position: relative;
  display: inline-block;
}

.profile-pic:hover .edit {
  display: block;
}

.edit {
  padding-top: 2px;
  padding-right: 4px;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}
.textareapop {
  width: 100% !important;
}

._CZjuD {
  position: absolute;
  top: 0%;
  width: 100%;
}
.activebtns {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.crack {
  whitespace: pre-wrap !important;
  overflowwrap: break-word !important;
  wordwrap: break-word !important;
}
.popover {
  position: relative;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  top: 80%;
  /* left: 40%; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* width: ; */
  /* max-width: 200px; */
}

@media (min-width: 768px) {
  .product-buttons {
    margin-left: 130px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .product-stock-button {
    margin-top: -120px !important;
  }
}

@media (min-width: 580px) and (max-width: 768px) {
  .product-stock-button {
    margin-top: -250px !important;
  }
}

@media (max-width: 768px) {
  .product-qurbani-button {
    margin-left: 0px !important;
  }
}

.mt-xs-neg-100 {
  margin-top: -150px !important;
}
@media (min-width: 576px) {
  .mt-sm-neg-150 {
    margin-top: -230px !important;
  }
}
@media (min-width: 768px) {
  .mt-md-neg-210 {
    margin-top: -180px !important;
  }
}
.mb-150 {
  margin-bottom: 150px !important;
}

.business-button {
  margin-top: -500px;
  margin-bottom: 150px;
  margin-left: 130px;
}

@media (min-width: 768px) and (max-width: 992px) {
  .business-button {
    margin-top: -120px;
    margin-bottom: 150px;
    margin-left: 130px;
  }
}

@media (max-width: 576px) {
  .business-button {
    margin-top: -240px;
    margin-bottom: 150px;
    margin-left: 130px;
  }
}

.quick-lauch-cards {
}
@media (max-width: 576px) {
  .quick-lauch-cards {
    margin-top: 50px;
  }
}
/* card section */
.cards-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media (max-width: 576px) {
  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 5rem;
    align-items: center;
  }
}
.card-top {
  justify-content: space-around;
  /*  */
}
.single-card {
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
.single-card:hover {
  transform: translateZ(20px) scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
@media (min-width: 576px) and (max-width: 768px) {
  .card-top {
    display: flex;
    gap: 2rem;
    max-width: 100%;
  }
}

@media  (max-width: 576px) {
  .card-top {
    display: flex;
    gap: 2rem;
    max-width: 90%;
  }
}
.card-text {
}

@media (max-width: 768px) {
  .card-text {
    font-size: 16px;
  }
}

.stock-card-col {
  background-color: #bad8f9;
  display: flex;
  align-items: center;
  border-radius: 11px;
}

@media (max-width: 768px) {
  .stock-card-col {
    background-color: #bad8f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 11px;
  }
}

.stock-chain-logo-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  max-width: 50%;
}

@media (max-width: 768px) {
  .stock-chain-logo-text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 100%;
  }
}
.card-img-tablet {
  max-width: 100%;
}

.card-logo {
  max-width: 60%;
}
.card-button-naseem {
  display: flex;
  justify-content: center;
}
.card-button-business {
  display: flex;
  justify-content: center;
}

.card-button-qurbani {
  display: flex;
  justify-content: center;
}
.card-button-optimus {
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) and (max-width: 992px) {
  .card-button-naseem {
    margin-top: 185px;
  }

  .card-button-qurbani {
    margin-top: 175px;
  }

  .card-button-optimus {
    margin-top: 35px;
  }
}

@media (min-width: 992px) {
  .card-button-naseem {
    margin-top: 135px;
  }

  .card-button-qurbani {
    margin-top: 105px;
  }

  .card-button-optimus {
    margin-top: 5px;
  }

  .card-button-business {
    margin-top: 15px;
  }
}

@media (min-width: 1200px) {
  .card-button-naseem {
    /* margin-top: 185px; */
  }

  .card-button-qurbani {
    /* margin-top: 145px; */
  }

  .card-button-optimus {
    margin-top: 30px;
  }

  .card-button-business {
    /* margin-top: 65px; */
  }
}


.slider-top {
  display: flex;
}
.row-newsletter-top {
  justify-content: space-around;
  background-color: white;
  border-radius: 11px;
  max-width: 95%;
}
@media (max-width: 768px) {
  .row-newsletter-top {
    justify-content: space-around;
    background-color: white;
    border-radius: 11px;
    max-width: 100%;
  }
}

.custom-background {
  /* background-color: white !important; */
  /* width: 2rem; */
  width: 190px;
  margin-left: 2rem;
  /* margin-right:2rem */
}

@media (max-width: 768px) {
  .bi-weekly-text-top {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .newsletter-read {
    text-align: center;
  }
}
.bottom-slider-container {
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
}

@media (max-width: 768px) {
  .bottom-slider-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.slider-top-div {
width: 30%;
}

@media (min-width: 768px)  and (max-width: 992px) {
  .slider-top-div {
    width: 40%;
    }
    
}


.subscribe-now-top {
  display: flex;
  gap: 7rem;
}
@media (max-width: 768px) {
  .subscribe-now-top {
    justify-content: center;
    gap: 0px;
  }
}
.subscribe-subscribe-top {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .subscribe-subscribe-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.footer-row {
  /* gap: 8rem; */
  justify-content: space-around;
}

@media (max-width: 992px) {
  .footer-row {
    /* gap:4rem */
  }
}
@media (max-width: 768px) {
  .footer-row {
    gap: 0rem;
  }
  .footer-casestudies {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .footer-sitemap {
    text-align: center;
  }
  .footer-contactus {
    text-align: center;
  
  }
}


.footer-casestudies {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.slick-list,
.slick-track {
  transition-delay: 50ms;
}







/* slider custom */

.slider {
  overflow: hidden;
  width: 100%;
}

.slide-track {
  display: flex;
  background-color: white !important;
  gap:12rem;
  animation: slide-animation 40s linear infinite;
}

.slide {
  flex: 0 0 auto;
}

.slide img {
  height:55px;
  width: auto; /* Adjust width as needed */
}

@keyframes slide-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



.slider-one {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 360px;
}

.slide-track-one {
  display: flex;
  background-color: white !important;
  gap:2.5rem;
  animation: slide-animation-one 40s linear infinite;
  height: 100%;
  flex-direction: column;
}

.slide-one {
  flex: 0 0 auto;

}

.slide-one img {
  height:105px;
  width: auto; /* Adjust width as needed */
}

@keyframes slide-animation-one {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}



.slider-two{
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 360px;
}

.slide-track-two {
  display: flex;
  background-color: white !important;
  gap:2.5rem;
  animation: slide-animation-two 40s linear infinite;
 height: 100%;
  flex-direction: column;
}

.slide-two {
  flex: 0 0 auto;
}

.slide-two img {
  height:105px;
  width: auto; /* Adjust width as needed */
}

@keyframes slide-animation-two {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}



.slider-products {
  overflow: hidden;
  width: 90%;
}

.slide-track-products  {
  display: flex;
  /* background-color: white !important; */
  gap:7rem;
  animation: slide-animation-products 40s linear infinite;
}

.slide-products  {
  flex: 0 0 auto;
}

.slide-products img {
  height:185px;
  width: auto; /* Adjust width as needed */
}

@keyframes slide-animation-products {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


.slider-services {
  overflow: hidden;
  width: 100%;
}

.slide-track-services {
  display: flex;
  /* background-color: white !important; */
  gap:2rem;
  animation: slide-animation-services 40s linear infinite;
}

.slide-services {
  flex: 0 0 auto;
}

.slide-services img {
  height:55px;
  width: auto; /* Adjust width as needed */
}

@keyframes slide-animation-services {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



